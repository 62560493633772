import { Accordion, AccordionDetails, AccordionSummary, Divider, Grid, Typography } from '@material-ui/core';
import React, { FC, Fragment, useState } from 'react';
import {
  TypeSportAthleteArchiveMutationHookResult,
  TypeSportAthleteUnArchiveMutationHookResult,
  useRefereeSportArchiveMutation,
  useRefereeSportUnArchiveMutation,
} from '../../../../api';

import ArchiveComponent from '../../../Dialogs/Archive/Archive';
import Category from './Category';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Scalars } from '../../../../api';
import WorkExperience from './WorkExperience';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  row: {
    display: 'flex',
  },
  btn: {
    padding: '5px 5px',
    minWidth: '15px',
  },
  tinyIcon: {
    fontSize: 18,
  },
  icon: {
    fontSize: 20,
  },
  tableHead: {
    fontWeight: 'bold',
  },
  header: {
    fontWeight: 600,
    fontSize: 16,
    letterSpacing: 1.2,
  },
  verticalSpace: {
    marginTop: 10,
    marginBottom: 10,
  },
});

interface formState {
  organization?: { label: string; value: any };
  trainingStage?: { label: any; value: any };
  trainer?: { label: string; value: Scalars['UUID'] };
  trainingStart?: Date;
  exclusionDate?: Date;
  isSelfTraining?: string;
  orgId?: Scalars['UUID'];
}

const SportAccordion: FC<{
  readonly?: boolean;
  sport: any;
  onUpdate: Function;
  refereeId: string;
}> = ({ readonly, sport, onUpdate, refereeId }) => {
  const classes = useStyles();

  const [archive]: TypeSportAthleteArchiveMutationHookResult = useRefereeSportArchiveMutation();
  const [unarchive]: TypeSportAthleteUnArchiveMutationHookResult = useRefereeSportUnArchiveMutation();

  console.log(sport);

  return (
    <Accordion key={sport.id}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`${sport.dirSport?.label}-content`}
        id={`${sport.dirSport?.label}-header`}
      >
        <Grid container justify="space-between" alignItems="center">
          <Grid item>
            <Typography className={classes.header} color="primary">
              {sport.dirSport.label}
            </Typography>
          </Grid>
          {!readonly && (
            <Grid item onClick={(e) => e.stopPropagation()}>
              <ArchiveComponent
                id={sport?.id}
                archiveStatus={sport.archive}
                canUnArchive
                unarchive={unarchive}
                refetch={onUpdate}
                archive={archive}
              />
            </Grid>
          )}
        </Grid>
      </AccordionSummary>
      <AccordionDetails style={{ flexDirection: 'column' }}>
        <Grid container>
          <WorkExperience
            id={sport?.id}
            data={sport?.workExperience}
            refetch={onUpdate}
            readOnly={sport.archive || readonly}
          />
        </Grid>
        <Divider className={classes.verticalSpace} />
        <Grid container>
          <Category
            id={sport?.id}
            idSport={sport?.dirSport?.value}
            data={sport?.categories}
            onUpdate={onUpdate}
            readOnly={readonly ? readonly : sport.archive}
          />
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default SportAccordion;
