import React, { FC, useState } from 'react';
import { Button, Grid, Link, Tooltip, IconButton, Typography, Box } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { makeStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import _ from 'lodash';
import ConfirmDialog from '../ConfirmDialog';
import getMessage from '../../messages';
import GetAppIcon from '@material-ui/icons/GetApp';
import { UploadFileMutationHookResult, useUploadFileMutation } from '../../api';

const useStyles = makeStyles({
  title: {
    fontWeight: 500,
    width: 270,
    display: 'inline-block',
  },
  bTitle: {
    margin: 0,
    fontSize: '15px',
    minWidth: 200,
  },
  subInfo: {
    color: 'lightgrey',
    fontStyle: 'italic',
  },
  link: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'flex-end',
  },
  verticalSpace: {
    paddingBottom: 15,
  },
  verticalSpaceMarge: {
    marginBottom: 15,
  },
  row: {
    pt: 0,
    pb: 0,
    mt: 0,
    mb: 0,
  },
  divider: {
    height: 1,
    width: '100%',
    marginBottom: '1rem',
  },
  btn: {
    padding: '5px 5px',
    minWidth: '15px',
  },
  tinyIcon: {
    fontSize: 18,
  },
  icon: {
    fontSize: 20,
  },
  input: {
    display: 'none',
  },
});

export const BtnDocument: FC<{
  readonly?: boolean;
  title?: string;
  showHint?: boolean;
  shouldCreateNew?: boolean;
  onAdd: any;
  onRemove: any;
  doc: any;
  docPath: string;
  notificationTypeNumber?: string;
  size?: number;
  fileFormat?: string;
  showFileHint?: boolean;
}> = ({
  readonly,
  title = '',
  showHint,
  shouldCreateNew,
  onAdd,
  onRemove,
  doc,
  docPath,
  notificationTypeNumber,
  size,
  fileFormat,
  showFileHint,
}) => {
  const DOWNLOAD_LINK = process.env.REACT_APP_BACKEND_URI?.replace('/graphql', '');
  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();

  const handleSnackBar = (variant: 'default' | 'error' | 'success' | 'warning' | 'info' | undefined, message: string) =>
    enqueueSnackbar(message, { variant });

  const [deleteItem, setDeleteItem] = useState<any>(null);
  const [uploadDocumentFile]: UploadFileMutationHookResult = useUploadFileMutation();

  const handleAddBFile = async (evt: any) => {
    const file = _.get(evt, 'target.files[0]', null);
    if (size && file.size > size) {
      alert('Размер загружаемого файла превышает допустимые 2Мб!');
      return;
    }
    try {
      if (file || (shouldCreateNew && file)) {
        uploadDocumentFile({
          variables: {
            file,
          },
        }).then(async (resp: any) => {
          const {
            uploadFile: { id: fileId },
          } = resp.data;
          onAdd(fileId, docPath, notificationTypeNumber);
          handleSnackBar('success', `Документ "${file?.name}" успешно добавлен`);
        });
      }
    } catch (e) {
      enqueueSnackbar(getMessage(e.message), { variant: 'error' });
    }
  };

  const handleDeleteBFile = async (item: any) => {
    try {
      if (item) {
        await onRemove(docPath);
        setDeleteItem(null);
        handleSnackBar('success', `Документ "${item?.name}" успешно удален`);
      }
    } catch (e) {
      enqueueSnackbar(getMessage(e.message), { variant: 'error' });
    }
  };

  const DocRow = ({ title, item }: { title: string; item: any }) => (
    <Grid item container alignItems="center" className={classes.row} spacing={1}>
      {item?.path ? (
        <>
          {title && (
            <Grid item>
              <h4 className={classes.bTitle}>{title}:</h4>
            </Grid>
          )}
          <Grid item>
            <Link
              // className={`${classes.link}`}
              target="_blank"
              href={`${DOWNLOAD_LINK}${item?.path}`}
            >
              <Button variant="outlined" component="span" size="small" color="primary" endIcon={<GetAppIcon />}>
                {item?.name}
              </Button>
            </Link>
            <Tooltip title="удалить" placement="top-start">
              <IconButton color="secondary" onClick={() => setDeleteItem({ ...item, name: title })} disabled={readonly}>
                <DeleteIcon className={classes.icon} />
              </IconButton>
            </Tooltip>
          </Grid>
        </>
      ) : (
        <>
          <Grid item>
            <h4 className={classes.bTitle}>{title}:</h4>
          </Grid>
          <Grid item>
            <label htmlFor="p-file">
              <input
                {...(fileFormat && { accept: fileFormat })}
                className={classes.input}
                id="p-file"
                type="file"
                onChange={(evt: any) => handleAddBFile(evt)}
                disabled={readonly}
              />
              <Button variant="contained" component="span" size="small" color="primary" disabled={readonly}>
                добавить
              </Button>
            </label>
          </Grid>
          {!doc && showFileHint && (
            <Grid item container>
              <Typography className={classes.subInfo} variant="subtitle1">
                файл до 2 Mb формата: pdf, jpeg, png
              </Typography>
            </Grid>
          )}
        </>
      )}
    </Grid>
  );

  return (
    <Box>
      {showHint && (
        <Grid item container>
          <h4 style={{ marginTop: 0 }}>
            <strong>PDF/Фото</strong> (фотографии должны быть качественные, необрезанные):
          </h4>
        </Grid>
      )}
      <DocRow item={doc} title={title} />
      {deleteItem && (
        <ConfirmDialog
          title={`Удаление документа`}
          text={`Вы уверены что хотите удалить документ : ${deleteItem?.name}`}
          btnVariant="outlined"
          open={true}
          saveBtnText="удалить"
          cancelBtnText="отмена"
          saveBtnColor="secondary"
          cancelBtnColor="primary"
          onCancel={() => setDeleteItem(null)}
          onSave={() => handleDeleteBFile(deleteItem)}
        />
      )}
    </Box>
  );
};
