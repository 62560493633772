import {
  Scalars,
  UpdateAgeGroupFemaleEventMutationHookResult,
  UpdateAgeGroupMaleEventMutationHookResult,
  UpdateSportingEventMutationHookResult,
  useEventAgeGroupsLazyQuery,
  useSportAgeGroupsLazyQuery,
  useSportingEventSportsLazyQuery,
  useUpdateAgeGroupFemaleEventMutation,
  useUpdateAgeGroupMaleEventMutation,
  useUpdateSportingEventMutation,
} from '../../../../api';
import { Divider, Grid, LinearProgress } from '@material-ui/core';
import React, { FC, useEffect, useMemo } from 'react';

import Disciplines from '../../../../module/Disciplines';
import EditLocationIcon from '@material-ui/icons/EditLocation';
import GroupDisciplines from '../../../../module/GroupDisciplines';
import TypeSportsMultiSelect from '../../../../module/TypeSportsMultiSelect';
import _ from 'lodash';
import { useForm } from 'react-hook-form';
import { Select } from '../../../Inputs';

interface EventInfo {
  classes?: any;
  SportUpdated: Function;
  onError: Function;
  id?: any;
  readOnly?: boolean;
  dChanges?: string[];
  withoutDivider?: boolean;
  onSportsBlockChanged?: ({
    sportTypes,
    disciplinesGroup,
    disciplines,
  }: {
    sportTypes?: Array<dirSportInterface>;
    disciplinesGroup?: Array<disciplineInterface>;
    disciplines?: Array<disciplineInterface>;
  }) => void;
}

interface dirSportInterface {
  value: Scalars['UUID'];
  label: string;
  disciplines?: Array<disciplineInterface>;
}
interface disciplineInterface {
  value: Scalars['UUID'];
  label: string;
  typeSport: {
    id: Scalars['UUID'];
  };
}

interface formInterface {
  dirSports: dirSportInterface[];
  disciplineGroups: disciplineInterface[];
  disciplines: disciplineInterface[];
}

const Sports: FC<EventInfo> = ({
  id,
  classes,
  SportUpdated,
  onError,
  dChanges,
  readOnly,
  withoutDivider,
  onSportsBlockChanged,
}) => {
  const [fetch, { data, loading }] = useSportingEventSportsLazyQuery({
    fetchPolicy: 'no-cache',
    variables: { filter: { event: { id } } },
  });

  const [fetchAgeGroups, { data: dataAgeGroups, loading: loadingAgeGroups }] = useEventAgeGroupsLazyQuery({
    fetchPolicy: 'no-cache',
    variables: { id: id },
  });

  const [updateFemaleAgeGroup]: UpdateAgeGroupFemaleEventMutationHookResult = useUpdateAgeGroupFemaleEventMutation();
  const [updateMaleAgeGroup]: UpdateAgeGroupMaleEventMutationHookResult = useUpdateAgeGroupMaleEventMutation();

  useEffect(() => {
    if (id !== null) {
      fetch();
      fetchAgeGroups();
    }
  }, [id]);

  const Sports = useMemo(() => data?.dirSportingEventSports || [], [data]);
  const ageGroupData = ([] = useMemo(() => {
    const valuesAgeGroups: any = [];
    if (data?.dirSportingEventSports && data?.dirSportingEventSports.length > 0) {
      for (const sportEvent of data?.dirSportingEventSports) {
        if (sportEvent.dirSport?.ageGroups && sportEvent.dirSport?.ageGroups.length > 0) {
          for (const ageGroup of sportEvent.dirSport?.ageGroups) {
            valuesAgeGroups.push({
              value: ageGroup.id,
              label: `${ageGroup.clsAgeGroup.label} (от ${ageGroup.minAge}${
                ageGroup.maxAge && ageGroup.maxAge > 0 ? ` до ${ageGroup.maxAge}` : ''
              })`,
            });
          }
        }
      }
    }

    return valuesAgeGroups;
  }, [data]));

  const dirSports: dirSportInterface[] = useMemo(() => Sports.map((s) => s?.dirSport as dirSportInterface), [Sports]);

  const disciplineGroups: disciplineInterface[] = useMemo(
    () =>
      _.flattenDeep(
        Sports.map(
          (s: any) =>
            s.disciplineGroups.map((d: any) => ({
              ...d,
              typeSport: { id: s?.dirSport?.value },
            })) as disciplineInterface,
        ),
      ),
    [Sports],
  );

  const disciplines: disciplineInterface[] = useMemo(
    () =>
      _.flattenDeep(
        Sports.map(
          (s: any) =>
            s.disciplines.map((d: any) => ({
              ...d,
              typeSport: { id: s?.dirSport?.value },
            })) as disciplineInterface,
        ),
      ),
    [Sports],
  );

  const { handleSubmit, control, errors, watch, reset, setValue } = useForm({
    defaultValues: {
      dirSports,
      disciplineGroups,
      ageGroupFemale:
        dataAgeGroups?.EventAgeGroups?.ageGroupFemale === null
          ? null
          : {
              value: dataAgeGroups?.EventAgeGroups?.ageGroupFemale?.id,
              label: `${dataAgeGroups?.EventAgeGroups?.ageGroupFemale?.clsAgeGroup.label} (от ${
                dataAgeGroups?.EventAgeGroups?.ageGroupFemale?.minAge
              }${
                dataAgeGroups?.EventAgeGroups?.ageGroupFemale?.maxAge &&
                dataAgeGroups?.EventAgeGroups?.ageGroupFemale?.maxAge > 0
                  ? ` до ${dataAgeGroups?.EventAgeGroups?.ageGroupFemale?.maxAge}`
                  : ''
              })`,
            },
      ageGroupMale:
        dataAgeGroups?.EventAgeGroups?.ageGroupMale === null
          ? null
          : {
              value: dataAgeGroups?.EventAgeGroups?.ageGroupMale?.id,
              label: `${dataAgeGroups?.EventAgeGroups?.ageGroupMale?.clsAgeGroup.label} (от ${
                dataAgeGroups?.EventAgeGroups?.ageGroupMale?.minAge
              }${
                dataAgeGroups?.EventAgeGroups?.ageGroupMale?.maxAge &&
                dataAgeGroups?.EventAgeGroups?.ageGroupMale?.maxAge > 0
                  ? ` до ${dataAgeGroups?.EventAgeGroups?.ageGroupMale?.maxAge}`
                  : ''
              })`,
            },
    } as any,
  });

  let watchSportTypes = watch('dirSports', dirSports);

  let watchDisciplinesGroup = watch('disciplineGroups', disciplineGroups);

  const [
    upDateSportingEvent,
    { loading: loadingMutation },
  ]: UpdateSportingEventMutationHookResult = useUpdateSportingEventMutation();
  const onSubmit = async (values: formInterface) => {
    console.log('this form wont be submited');
  };

  const updateSport = async (sports: any) => {
    const newSports = _.difference(sports, dirSports).map((s) => ({
      dirSport: {
        connect: { id: s.value },
      },
    }));
    const deleteSports = _.difference(dirSports, sports);
    if ((deleteSports.length === 0 && newSports.length === 0) || id === null) return null;
    try {
      // await request();
      await upDateSportingEvent({
        variables: {
          data: {
            sports: {
              create: newSports,
              delete: Sports.filter((s: any) => deleteSports.includes(s?.dirSport)).map((s) => ({ id: s.id })),
            },
          },
          id,
        },
      }).then(async (res) => {
        setValue(
          'disciplineGroups',
          watchDisciplinesGroup?.filter(
            (group: any) => watchSportTypes?.find((type: any) => type?.value === group?.typeSport?.id) !== undefined,
          ),
        );
        SportUpdated();
      });
    } catch (error) {
      onError(error);
    }
  };

  const updateDisciplinesGroups = async (disciplineGroups: any) => {
    try {
      await upDateSportingEvent({
        variables: {
          data: {
            sports: {
              update: Sports.map((s) => ({
                where: { id: s.id },
                data: {
                  disciplineGroups: {
                    set: disciplineGroups
                      .filter((d: any) => d.typeSport.id === _.get(s, 'dirSport.value'))
                      .map((d: any) => ({ id: d.value })),
                  },
                },
              })),
            },
          },
          id,
        },
      }).then((res) => {
        fetch();
        SportUpdated();
      });
    } catch (error) {
      onError(error);
    }
  };

  const updateDisciplines = async (disciplines: any) => {
    try {
      await upDateSportingEvent({
        variables: {
          data: {
            sports: {
              update: Sports.map((s) => ({
                where: { id: s.id },
                data: {
                  disciplines: {
                    set: disciplines
                      .filter((d: any) => d.typeSport.id === _.get(s, 'dirSport.value'))
                      .map((d: any) => ({ id: d.value })),
                  },
                },
              })),
            },
          },
          id,
        },
      }).then((res) => {
        SportUpdated();
      });
    } catch (error) {
      onError(error);
    }
  };

  const sportsFilter = useMemo(() => {
    return {
      dirSport: {
        id_in: watchSportTypes?.map((type: any) => type?.value),
      },
    };
  }, [watchSportTypes]);

  useEffect(() => {
    reset({
      dirSports,
      disciplineGroups,
      disciplines,
      ageGroupFemale:
        dataAgeGroups?.EventAgeGroups?.ageGroupFemale === null
          ? null
          : {
              value: dataAgeGroups?.EventAgeGroups?.ageGroupFemale?.id,
              label: `${dataAgeGroups?.EventAgeGroups?.ageGroupFemale?.clsAgeGroup.label} (от ${
                dataAgeGroups?.EventAgeGroups?.ageGroupFemale?.minAge
              }${
                dataAgeGroups?.EventAgeGroups?.ageGroupFemale?.maxAge &&
                dataAgeGroups?.EventAgeGroups?.ageGroupFemale?.maxAge > 0
                  ? ` до ${dataAgeGroups?.EventAgeGroups?.ageGroupFemale?.maxAge}`
                  : ''
              })`,
            },
      ageGroupMale:
        dataAgeGroups?.EventAgeGroups?.ageGroupMale === null
          ? null
          : {
              value: dataAgeGroups?.EventAgeGroups?.ageGroupMale?.id,
              label: `${dataAgeGroups?.EventAgeGroups?.ageGroupMale?.clsAgeGroup.label} (от ${
                dataAgeGroups?.EventAgeGroups?.ageGroupMale?.minAge
              }${
                dataAgeGroups?.EventAgeGroups?.ageGroupMale?.maxAge &&
                dataAgeGroups?.EventAgeGroups?.ageGroupMale?.maxAge > 0
                  ? ` до ${dataAgeGroups?.EventAgeGroups?.ageGroupMale?.maxAge}`
                  : ''
              })`,
            },
    });
  }, [dirSports, disciplineGroups, disciplines, reset, dataAgeGroups]);

  const updateManAgeGroup = async (data: any) => {
    try {
      const val = data?.value
        ? {
            connect: { id: data?.value },
          }
        : { disconnect: true };
      await updateMaleAgeGroup({
        variables: {
          data: val,
          id,
        },
      }).then((res) => {
        SportUpdated();
        fetchAgeGroups();
      });
    } catch (error) {
      onError(error);
    }
  };

  const updateWomanAgeGroup = async (data: any) => {
    try {
      const val = data?.value
        ? {
            connect: { id: data?.value },
          }
        : { disconnect: true };
      await updateFemaleAgeGroup({
        variables: {
          data: val,
          id,
        },
      }).then((res) => {
        SportUpdated();
        fetchAgeGroups();
      });
    } catch (error) {
      onError(error);
    }
  };

  // useEffect(() => {
  //   if (id !== undefined && !_.isEqual(disciplineGroups, watchDisciplinesGroup))
  //     updateDisciplinesGroups({ disciplineGroups: watchDisciplinesGroup });
  //   else if (id === undefined && onSportsBlockChanged)
  //     onSportsBlockChanged({ sportTypes: watchSportTypes, disciplinesGroup: watchDisciplinesGroup });
  // }, [disciplineGroups, id, onSportsBlockChanged, updateDisciplinesGroups, watchDisciplinesGroup, watchSportTypes]);
  // //
  // useEffect(() => {
  //   if (id !== undefined && !_.isEqual(disciplines, watchDisciplines))
  //     updateDisciplines({ disciplines: watchDisciplines });
  //   else if (id === undefined && onSportsBlockChanged)
  //     onSportsBlockChanged({
  //       sportTypes: watchSportTypes,
  //       disciplinesGroup: watchDisciplinesGroup,
  //       disciplines: watchDisciplines,
  //     });
  // }, [watchDisciplines]);

  if (loading) return <LinearProgress />;

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
        <Grid container className={!withoutDivider ? classes.verticalSpaceMarge : ''} spacing={2}>
          <Grid item container alignItems="flex-end">
            <Grid item xs>
              <TypeSportsMultiSelect
                label="Вид спорта"
                control={control}
                error={!!errors['dirSports']}
                name="dirSports"
                disabled={readOnly}
                onSuperChange={async (event: any, data: any) => {
                  await updateSport(data);
                }}
              />
            </Grid>
            {dChanges?.includes('dirSport') && (
              <Grid item>
                <EditLocationIcon color="secondary" />
              </Grid>
            )}
          </Grid>
          {watchSportTypes?.length > 0 && (
            <>
              <Grid item container alignItems="flex-end">
                <Grid item xs>
                  <GroupDisciplines
                    filter={sportsFilter}
                    label="Группы дисциплин"
                    control={control}
                    error={!!errors['disciplineGroups']}
                    name="disciplineGroups"
                    multiple
                    onSuperChange={async (event: any, data: any) => {
                      await updateDisciplinesGroups(data);
                    }}
                    disabled={readOnly}
                  />
                </Grid>

                {dChanges?.includes('disciplineGroups') && (
                  <Grid item>
                    <EditLocationIcon color="secondary" />
                  </Grid>
                )}
              </Grid>
              <Grid item container alignItems="flex-end">
                <Grid item xs>
                  <Disciplines
                    filter={sportsFilter}
                    label="Дисциплины"
                    control={control}
                    error={!!errors['disciplines']}
                    name="disciplines"
                    multiple
                    onSuperChange={async (event: any, data: any) => {
                      await updateDisciplines(data);
                    }}
                    disabled={readOnly}
                  />
                </Grid>

                {dChanges?.includes('disciplines') && (
                  <Grid item>
                    <EditLocationIcon color="secondary" />
                  </Grid>
                )}
              </Grid>
            </>
          )}
          <Grid item container spacing={2}>
            <Grid item xs={6}>
              <Select
                label="Возрастная группа муж."
                data={ageGroupData}
                control={control}
                error={false}
                name="ageGroupMale"
                loading={loading}
                disabled={readOnly}
                onSuperChange={async (event: any, data: any) => {
                  await updateManAgeGroup(data);
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <Select
                label="Возрастная группа жен."
                data={ageGroupData}
                control={control}
                error={false}
                name="ageGroupFemale"
                loading={loading}
                disabled={readOnly}
                onSuperChange={async (event: any, data: any) => {
                  await updateWomanAgeGroup(data);
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </form>
      {!withoutDivider && (
        <Grid container>
          <Divider className={classes.divider} />
        </Grid>
      )}
    </React.Fragment>
  );
};

export default Sports;
