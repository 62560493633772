import {
  Accordion,
  AccordionDetails,
  AccordionSummary as MuiAccordionSummary,
  Typography,
  withStyles,
} from '@material-ui/core';
import { ContentWrapperWidget, MainTemplate } from '../../components/layouts';
import { CreateAthleteMutationHookResult, useAthleteLazyQuery, useCreateAthleteMutation } from '../../api';
import React, { ChangeEvent, FC, useEffect, useState } from 'react';

import AdditionalInfo from '../../components/widgets/Persons/AdditionalInfo';
import Duplicates from '../../components/widgets/Persons/Duplicates';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MainInfo from '../../components/widgets/Persons/MainInfo';
import Medic from '../../components/widgets/Athletes/Medic';
import TypeSports from '../../components/widgets/Athletes/TypeSports';
import _get from 'lodash/get';
import { blue } from '@material-ui/core/colors';
import moment from 'moment';
import onExpand from '../../utils/onExpand';
import { useHistory } from 'react-router';
import { usePermission } from '../../hooks/usePermission';
import { useRouteMatch } from 'react-router-dom';

type AccordionTypes =
  | 'MainInfo'
  | 'Organizations'
  | 'ResultChallenge'
  | 'TypeSports'
  | 'AdditionalInfo'
  | 'MedCertificate'
  | 'Duplicates'
  | 'PersonalTrainer'
  | undefined;

const AccordionSummary = withStyles({
  root: {
    backgroundColor: blue[700],
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    color: 'white',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary) as any;

// @TODO: Update View for Dialog request to Archive

const Single: FC<{}> = () => {
  const {
    params: { id },
  } = useRouteMatch();
  // const [page, setPage] = useState<number>(0);
  const { push } = useHistory();

  const [expanded, setExpanded] = useState<AccordionTypes>('MainInfo');

  const access = usePermission('directory', 'athletes');

  // const access = usePermission('directory', 'person');
  // const style = useStyle();
  // const canAdd = true;
  const [fetch, { data, loading }] = useAthleteLazyQuery({ variables: { filter: { id } } });
  const [create]: CreateAthleteMutationHookResult = useCreateAthleteMutation();
  const handleChangeAccordion = (panel: AccordionTypes) => (event: ChangeEvent<{}>, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : undefined);
  };

  const OptionTitleArchive = data?.dirAthlete?.archive
    ? ` (В архиве с ${moment(data?.dirAthlete?.archive?.date).format('DD.MM.YYYY')})`
    : '';

  const regionsId = [
    data?.dirAthlete?.dirPerson?.dirRegion?.value,
    ...(data?.dirAthlete?.dirPerson?.additionalRegions?.map((a) => a?.dirRegion?.id).filter((a) => a) || []),
  ].filter((a) => a);

  useEffect(() => {
    if (id !== 'new' && id !== '') {
      fetch({ variables: { filter: { id } } });
    }
  }, [fetch, id]);

  // @ts-ignore
  return (
    <MainTemplate
      pageName=""
      url=""
      title={
        id === 'new'
          ? 'Добавить нового спортсмена'
          : `Спортсмен: ${data?.dirAthlete?.dirPerson?.lastname} ${data?.dirAthlete?.dirPerson?.firstname} ${
              data?.dirAthlete?.dirPerson?.patronymic || ''
            }`
      }
    >
      <ContentWrapperWidget>
        {id === 'new' ? (
          <h2>Добавить нового спортсмена</h2>
        ) : (
          <h2>
            {`Реестровый номер: ${data?.dirAthlete?.dirPerson?.registryNumber || ''} , `}
            Спортсмен: {data?.dirAthlete?.dirPerson?.lastname} {data?.dirAthlete?.dirPerson?.firstname}{' '}
            {`${_get(data?.dirAthlete?.dirPerson, 'patronymic', '') || ''} `}
            {moment(data?.dirAthlete?.dirPerson?.birthday).format('DD.MM.YYYY')}
            {OptionTitleArchive}
          </h2>
        )}
        <Accordion expanded={expanded === 'MainInfo'} onChange={handleChangeAccordion('MainInfo')}>
          <AccordionSummary expandIcon={<ExpandMoreIcon htmlColor="white" />}>
            <Typography>Общая информация</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {onExpand(expanded, 'MainInfo') && (
              <MainInfo
                id={data?.dirAthlete?.dirPerson?.id || 'new'}
                readonly={!access.includes(2)}
                isAthlete
                onUpdate={async (idPerson, sportType) => {
                  if (id === 'new') {
                    const athlete = await create({ variables: { idPerson, sportType } });
                    push(`/athlete/${athlete?.data?.createDirAthlete?.id}`);
                  }
                }}
              />
            )}
          </AccordionDetails>
        </Accordion>
        <Accordion
          disabled={id === 'new'}
          expanded={expanded === 'AdditionalInfo'}
          onChange={handleChangeAccordion('AdditionalInfo')}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon htmlColor="white" />}>
            <Typography>Контактная информация</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {onExpand(expanded, 'AdditionalInfo') && data?.dirAthlete?.dirPerson?.id && (
              <AdditionalInfo id={data?.dirAthlete?.dirPerson.id} readonly={!access.includes(2)} />
            )}
          </AccordionDetails>
        </Accordion>
        <Accordion
          disabled={id === 'new'}
          expanded={expanded === 'TypeSports'}
          onChange={handleChangeAccordion('TypeSports')}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon htmlColor="white" />}>
            <Typography>Виды спорта</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {onExpand(expanded, 'TypeSports') && data?.dirAthlete?.dirPerson?.id && (
              <TypeSports id={id} regionsId={regionsId} readonly={!access.includes(2)} />
            )}
          </AccordionDetails>
        </Accordion>
        <Accordion
          disabled={id === 'new'}
          expanded={expanded === 'MedCertificate'}
          onChange={handleChangeAccordion('MedCertificate')}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon htmlColor="white" />}>
            <Typography>Допуски</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {onExpand(expanded, 'MedCertificate') && data?.dirAthlete?.dirPerson?.id && (
              <Medic id={id} readonly={!access.includes(2)} />
            )}
          </AccordionDetails>
        </Accordion>

        <Accordion
          disabled={id === 'new'}
          expanded={expanded === 'Duplicates'}
          onChange={handleChangeAccordion('Duplicates')}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon htmlColor="white" />}>
            <Typography>Дубликаты</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {onExpand(expanded, 'Duplicates') && data?.dirAthlete?.dirPerson?.id && (
              <Duplicates id={data?.dirAthlete?.dirPerson?.id} readonly={!access.includes(2)} />
            )}
          </AccordionDetails>
        </Accordion>
      </ContentWrapperWidget>
    </MainTemplate>
  );
};

export default Single;
