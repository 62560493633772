import { Button, Dialog, DialogTitle, Grid } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import {
  DirSportDiscipline,
  DirSportingEvent,
  DirSportingEventSport,
  NationalTeamAddAthleteGroupMutationHookResult,
  NationalTeamAddAthleteMutationHookResult,
  NationalTeamDeleteAthleteGroupMutationHookResult,
  NationalTeamDeleteAthleteMutationHookResult,
  NationalTeamUpdateAthleteGroupMutationHookResult,
  Scalars,
  useNationalTeamAddAthleteGroupMutation,
  useNationalTeamAddAthleteMutation,
  useNationalTeamDeleteAthleteGroupMutation,
  useNationalTeamDeleteAthleteMutation,
  useNationalTeamUpdateAthleteGroupMutation,
} from '../../../../api';
import React, { FC, Fragment, useMemo, useState } from 'react';

import AthleteGroupsAccordion from './AthleteGroupsAccordion';
import { Select } from '../../../Inputs';
import _ from 'lodash';
import getMessage from '../../../../messages';
import { revealGroups, setAthleteYearInfo } from './helpers';
import { useSnackbar } from 'notistack';

interface GroupInterface extends Pick<DirSportingEvent, 'startDate'> {
  classes: any;
  groupsArray: any;
  readonly?: boolean;
  sports: any;
  programTypes: any;
  onAthleteGroupsUpdated: Function;
  id: Scalars['UUID'];
  canAddAthlete: boolean;
  athleteIsInRange?: boolean;
  athleteIsInRangeRank?: boolean;
  region: String;
  university?: String;
  event: any;
  additionalParams: any;
}

const AthletesGroupsComponent: FC<GroupInterface> = ({
  classes,
  id,
  readonly,
  groupsArray = [],
  onAthleteGroupsUpdated,
  sports = [],
  programTypes = [],
  canAddAthlete,
  athleteIsInRange,
  athleteIsInRangeRank,
  region,
  university,
  event,
  startDate,
  additionalParams,
}) => {
  const defaultAthGroupState: any = {
    ageGroups: undefined,
    disciplineGroups: undefined,
  };
  const [isShowingResult, setIsShowingResult] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [currentRequest, setCurrentRequest] = useState('');
  const [groupId, setGroupId] = useState('');

  const athletesLength = useMemo(() => groupsArray?.reduce((acc: number, curr: any) => acc + curr.athletes.length, 0), [
    groupsArray,
  ]);

  const { minManBirthYear, maxManBirthYear, minWomanBirthYear, maxWomanBirthYear } = useMemo(() => event || {}, [
    event,
  ]);

  const { bannedAthletesFromSearch, array } = revealGroups(groupsArray, startDate);

  const { enqueueSnackbar } = useSnackbar();

  const [addAthleteGroup]: NationalTeamAddAthleteGroupMutationHookResult = useNationalTeamAddAthleteGroupMutation();
  const [
    updateAthleteGroup,
  ]: NationalTeamUpdateAthleteGroupMutationHookResult = useNationalTeamUpdateAthleteGroupMutation();
  const [
    deleteAthleteGroup,
  ]: NationalTeamDeleteAthleteGroupMutationHookResult = useNationalTeamDeleteAthleteGroupMutation();
  const [deleteAthlete]: NationalTeamDeleteAthleteMutationHookResult = useNationalTeamDeleteAthleteMutation();

  const onAddAthleteGroup = async (values: any) => {
    const { minAge, maxAge, ageGroups, disciplineGroups } = values;
    const isOk = ageGroups?.length > 0 && disciplineGroups?.length > 0;
    if (!isOk) return;
    try {
      if (currentRequest === 'update') {
        await updateAthleteGroup({
          variables: {
            id,
            ageGroupsId: ageGroups.map((a: { value: string; type_name: string }) => ({ id: a.value })),
            disciplineGroupsId: disciplineGroups.map(
              (d: DirSportDiscipline & { label: string; value: Scalars['UUID'] }) => ({
                id: d.value,
              }),
            ),
            groupId,
          },
        });
        enqueueSnackbar(`Группа  успешно обновлена`, { variant: 'success' });
        reset(defaultAthGroupState);
        setOpenDialog(false);
        onAthleteGroupsUpdated();
        return;
      }
      await addAthleteGroup({
        variables: {
          id,
          ageGroupsId: ageGroups.map((a: { value: string; type_name: string }) => ({ id: a.value })),
          disciplineGroupsId: disciplineGroups.map(
            (d: DirSportDiscipline & { label: string; value: Scalars['UUID'] }) => ({
              id: d.value,
            }),
          ),
        },
      });
      enqueueSnackbar(`Группа  успешно добавлена`, { variant: 'success' });
      reset(defaultAthGroupState);
      setGroupId('');
      setOpenDialog(false);
      onAthleteGroupsUpdated();
    } catch (e) {
      enqueueSnackbar(getMessage(e.message), { variant: 'error' });
    }
  };
  const onDeleteAthleteGroup = async (groupId: string) => {
    try {
      await deleteAthleteGroup({
        variables: {
          id,
          groupId,
        },
      });
      enqueueSnackbar(`Группа  успешно удалено`, { variant: 'success' });
      onAthleteGroupsUpdated();
    } catch (e) {
      enqueueSnackbar(getMessage(e.message), { variant: 'error' });
    }
  };

  const onDeleteAthlete = async (groupId: string, athleteId: string) => {
    try {
      await deleteAthlete({
        variables: {
          id,
          groupId,
          athleteId,
        },
      });
      enqueueSnackbar(`Спортсмен  успешно удалено`, { variant: 'success' });
      onAthleteGroupsUpdated();
    } catch (e) {
      enqueueSnackbar(getMessage(e.message), { variant: 'error' });
    }
  };

  const { handleSubmit, control, errors, reset, formState } = useForm({
    defaultValues: defaultAthGroupState,
  });

  return (
    <Fragment>
      <Grid item container alignItems="center" className={classes.verticalSpace}>
        <Grid item xs={2}>
          <span className={classes.title}>{`Спортсмены: ${athletesLength}`}</span>
        </Grid>

        <Grid item container justify="space-between" md={8} sm={12}>
          {!readonly && (
            <Grid item>
              <Button variant="contained" color="primary" size="small" onClick={() => setOpenDialog(true)}>
                Добавить группу
              </Button>
            </Grid>
          )}
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              size="small"
              disabled={athletesLength === 0}
              onClick={() => setIsShowingResult(!isShowingResult)}
            >
              {(isShowingResult && 'Скрывать результат') || 'Показать результат'}
            </Button>
          </Grid>
        </Grid>
      </Grid>

      {event && (
        <Grid container direction={'column'} style={{ marginBottom: 15, marginTop: 15 }}>
          <span style={{ display: 'flex', fontSize: 15, fontWeight: 'bold' }}>Требование к участникам:</span>
          <span style={{ display: 'flex' }}>
            {setAthleteYearInfo(minManBirthYear, maxManBirthYear, minWomanBirthYear, maxWomanBirthYear).manYearInfo}
          </span>
          <span style={{ display: 'flex' }}>
            {setAthleteYearInfo(minManBirthYear, maxManBirthYear, minWomanBirthYear, maxWomanBirthYear).womanYearInfo}
          </span>
        </Grid>
      )}

      {array?.map((group) => (
        <AthleteGroupsAccordion
          key={group.id}
          group={group}
          bannedAthletesFromSearch={bannedAthletesFromSearch}
          region={region}
          university={university}
          additionalParams={additionalParams}
          id={id}
          athleteIsInRange={athleteIsInRange}
          athleteIsInRangeRank={athleteIsInRangeRank}
          event={event}
          canAddAthlete={canAddAthlete}
          readonly={readonly}
          isShowingResult={isShowingResult}
          onAthleteGroupsUpdated={onAthleteGroupsUpdated}
          onUpdateGroup={() => {
            setCurrentRequest('update');
            setGroupId(group?.id);
            reset({
              ...group,
              disciplineGroups: group?.disciplineGroups,
              ageGroups: group?.clsAgeGroups?.map((age: any) => ({
                ...age,
                label: age.fullName,
                value: age.id,
              })),
            });
            setOpenDialog(true);
          }}
          onDeleteGroup={() => onDeleteAthleteGroup(group.id)}
          onDeleteAthlete={onDeleteAthlete}
        />
      ))}

      <Dialog
        onClose={() => {
          setOpenDialog(false);
          reset(defaultAthGroupState);
          setGroupId('');
          setCurrentRequest('');
        }}
        aria-labelledby="add-athlete-group-dialog"
        open={openDialog}
      >
        <DialogTitle id="add-calendar-dialog">
          {(currentRequest && 'Редактировать группу') || 'Новая группа'}
        </DialogTitle>
        <div style={{ padding: '1.5rem' }}>
          <form onSubmit={handleSubmit(onAddAthleteGroup)} style={{ width: '100%' }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Select
                  multiple
                  data={_.uniqBy(
                    _.flattenDeep(sports?.map((sport: DirSportingEventSport) => sport?.disciplineGroups)),
                    'value',
                  )}
                  name="disciplineGroups"
                  label="Группы дисциплин"
                  control={control}
                  error={!!errors['disciplineGroups']}
                  rules={{ required: true }}
                />
              </Grid>
              <Grid item xs={12}>
                <Select
                  multiple
                  data={_.uniqBy(_.flattenDeep(programTypes.map((type: any) => type?.clsAgeGroups)), 'value')}
                  name="ageGroups"
                  label="Возрастные группы"
                  control={control}
                  error={!!errors['ageGroups']}
                  rules={{ required: true }}
                />
              </Grid>

              <Grid item xs={12}>
                <Button
                  style={{ marginLeft: 'auto', display: 'block' }}
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={!formState.isDirty}
                >
                  Добавить
                </Button>
              </Grid>
            </Grid>
          </form>
        </div>
      </Dialog>
    </Fragment>
  );
};

export default AthletesGroupsComponent;
