import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  LinearProgress,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@material-ui/core';
import {
  NationalTeamArchiveMutationHookResult,
  NationalTeamUnArchiveMutationHookResult,
  Scalars,
  UpdateSportingEventMutationHookResult,
  useNationalTeamArchiveMutation,
  useNationalTeamPeopleQuery,
  useNationalTeamUnArchiveMutation,
  useSportingEventUnArchivedTeamInfoQuery,
  useUpdateSportingEventMutation,
} from '../../../api';
import React, { FC, useEffect, useMemo, useState } from 'react';

import AddBoxIcon from '@material-ui/icons/AddBox';
import ArchiveComponent from '../../Dialogs/Archive/Archive';
import DownloadIcon from '@material-ui/icons/InsertDriveFile';
import EditIcon from '@material-ui/icons/Edit';
import { EventProtocolDoc } from './components';
import { DatePicker, Input } from '../../Inputs';
import Regions from '../../../module/Regions';
import SaveIcon from '@material-ui/icons/Save';
import TeamsPossibleRoiv from '../../../module/TeamsPossibleRoiv';
import getMessage from '../../../messages';
import { makeStyles } from '@material-ui/core/styles';
import { useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import userRights from '../../../config/userRights';
import TeamPeopleAccordion from './components/TeamPeople';
import TeamInfoDocs from './components/TeamInfoDocs';
import AthleteDocuments from './AthleteDocuments';
import Radios from '../../Inputs/Radio';
import { teamCount } from './helpers/teamCount';
import { fetchMandatoryReport } from '../../BtnReport/services/fetchMandatoryReport';
import LoadingWall from '../../LoadingWall/LoadingWall';
import UniversitiesByRegion from '../../../module/UniversitiesByRegion';
import _ from 'lodash';
import { fetchParticipantsGeneralReport } from '../../BtnReport/services/fetchParticipantsGeneralReport';

const useStyles = makeStyles({
  row: {
    display: 'flex',
  },
  grayRow: {
    '& > th': {
      color: 'gray',
      backgroundColor: '#e0e0e0',
    },
    '& > td': {
      color: 'gray',
      backgroundColor: '#e0e0e0',
    },
  },
  title: {
    fontWeight: 500,
  },
  link: {
    cursor: 'pointer',
  },
  btn: {
    padding: '5px 5px',
    minWidth: '15px',
  },
  icon: {
    fontSize: 20,
  },
  tableSize: {
    maxWidth: 100,
    width: 65,
    // minWidth: 100,
  },
  tableSizeTh: {
    maxWidth: 125,
    width: 125,
    padding: 8,
  },
  tableEmail: {
    maxWidth: 150,
    width: 150,
    minWidth: 150,
    wordBreak: 'break-all',
  },
});

interface formState {
  dirRegion?: { label: string; value: Scalars['UUID'] };
  university?: { label: string; value: Scalars['UUID'] };
  athletesCall?: string;
  manTeamQuota?: string;
  womanTeamQuota?: string;
  supportTeamQuota?: string;
  score?: string;
  point?: string;
  emailRecipients?: Array<{ value: string; label: string }>;
  athleteQuotaByGender: string | null;
  athleteTotalQuota?: string;
}

interface secondFormState {
  membersQuota?: number | null;
}

const TeamInfo: FC<{ readonly?: boolean; id?: string; status?: any; event?: any; isUniverciad?: boolean }> = ({
  readonly,
  id,
  status,
  event,
  isUniverciad,
}) => {
  const classes = useStyles();

  const {
    isRegionalAdmin,
    isRoivFspcr,
    adminRegion,
    userRegion,
    isRegionalSchool,
    regionalSchoolOrg,
    isGskReferee,
  } = userRights();
  const rUser = isRegionalAdmin || isRoivFspcr;
  const fRegion = adminRegion || userRegion;

  const defaultState: formState = {
    dirRegion: undefined,
    athletesCall: undefined,
    manTeamQuota: undefined,
    womanTeamQuota: undefined,
    supportTeamQuota: undefined,
    score: undefined,
    point: undefined,
    emailRecipients: undefined,
    athleteQuotaByGender: 'true',
    athleteTotalQuota: undefined,
  };
  const [teamId, setTeamId] = useState<Scalars['UUID']>('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [openDateDialog, setOpenDateDialog] = useState(false);
  const DOWNLOAD_LINK = process.env.REACT_APP_BACKEND_URI?.replace('/graphql', '');
  const [openDialog, setOpenDialog] = useState(false);
  const [currentRequest, setCurrentRequest] = useState('');
  const [isGeneratingDoc, setIsGeneratingDoc] = useState(false);
  const [updateEvent]: UpdateSportingEventMutationHookResult = useUpdateSportingEventMutation();
  const [archive]: NationalTeamArchiveMutationHookResult = useNationalTeamArchiveMutation();
  const [unarchive]: NationalTeamUnArchiveMutationHookResult = useNationalTeamUnArchiveMutation();

  const { loading, data, refetch } = useSportingEventUnArchivedTeamInfoQuery({
    returnPartialData: true,
    variables: {
      id,
    },
  });

  const {
    data: teamPeopleData,
    loading: isTeamPeopleLoading,
    refetch: refetchTeamPeopleData,
  } = useNationalTeamPeopleQuery({
    fetchPolicy: 'no-cache',
    skip: true,
  });
  const isUniversityEvent = useMemo(
    () => data?.dirSportingEvent?.dirCalendar?.clsCalendarCategory?.registryNumber === 100003375,
    [data],
  );
  const regionList = useMemo(
    () => data?.dirSportingEvent?.dirNationalTeams?.map((team) => team?.dirRegion?.value) || [],
    [data],
  );
  const universityList = useMemo(
    () => data?.dirSportingEvent?.dirNationalTeams?.map((team) => team?.university?.value) || [],
    [data],
  );
  const teamIsVerified = (team: any) => team?.statuses?.find((s: any) => s.isActive)?.value?.id === 4;

  const [membersQuota, dirNationalTeams] = [
    data?.dirSportingEvent?.membersQuota,
    data?.dirSportingEvent?.dirNationalTeams?.filter((t) => !t?.archive),
  ];

  const {
    handleSubmit: handleSecondSubmit,
    control: secondFormControl,
    reset: secondFormReset,
    errors: secondFormError,
    formState: secondFormState,
  } = useForm({
    defaultValues: {
      membersQuota,
    } as secondFormState,
  });

  const { handleSubmit, control, errors, reset, formState, watch } = useForm({
    defaultValues: defaultState,
  });

  const {
    handleSubmit: dateFormSubmit,
    control: dateFormControl,
    errors: dateFormErrors,
    formState: dateFormState,
  } = useForm();

  const factQuota = useMemo(
    () =>
      dirNationalTeams
        ?.filter((t) => !t?.archive)
        .reduce((acc: number, current: any) => acc + (teamCount(current).total ?? 0), 0),
    [dirNationalTeams],
  );

  useEffect(() => {
    secondFormReset({ membersQuota });
  }, [membersQuota, secondFormReset]);

  let region = watch('dirRegion');
  let university = watch('university');
  let emailRecipients = watch('emailRecipients');

  let athleteQuotaByGender = watch('athleteQuotaByGender');

  const { enqueueSnackbar } = useSnackbar();

  const handleSnackBar = (variant: 'default' | 'error' | 'success' | 'warning' | 'info' | undefined, message: string) =>
    enqueueSnackbar(message, { variant });

  const isSpecialUser = (team: any) =>
    (rUser && fRegion === team.dirRegion.value) || (isRegionalSchool && regionalSchoolOrg === team.university?.value);

  const getTeamRegion = (team: any) =>
    (isSpecialUser(team) && (
      <Link target="_blank" href={`/national_team/${team?.id}`}>
        {team.dirRegion.label || '~'}
      </Link>
    )) ||
    (((rUser && fRegion !== team.dirRegion?.value) ||
      (isRegionalSchool && regionalSchoolOrg !== team.university?.value)) && (
      <span> {team.dirRegion.label || '~'}</span>
    )) ||
    (!rUser && !isRegionalSchool && (
      <Link target="_blank" href={`/national_team/${team?.id}`}>
        {team.dirRegion.label || '~'}
      </Link>
    ));

  const onUpdateMembersQuota = async (values: secondFormState) => {
    const { membersQuota } = values;
    const factValue =
      (dirNationalTeams?.reduce(
        (acc: number, current: any) =>
          acc + current.athleteGroups &&
          current.athleteGroups?.reduce(
            (groupAcc: number, groupCurrent: any) =>
              groupAcc + groupCurrent?.athletes && groupCurrent?.athletes?.length,
            0,
          ),
        0,
      ) || 0) +
      (dirNationalTeams?.reduce((acc: number, current: any) => acc + current.specialists?.length, 0) || 0) +
      (dirNationalTeams?.reduce((acc: number, current: any) => acc + current.trainers?.length, 0) || 0) +
      (dirNationalTeams?.reduce((acc: number, current: any) => acc + current.medics?.length, 0) || 0) +
      (dirNationalTeams?.reduce((acc: number, current: any) => acc + current.delegationHeads?.length, 0) || 0);
    try {
      if (membersQuota && Number(membersQuota) < factValue) {
        handleSnackBar('error', 'Общее количество участников  должен быть больше чем Фактическое количество');
        return;
      }

      await updateEvent({
        variables: {
          data: {
            membersQuota: Number(membersQuota) || 0,
          },
          id,
        },
      });

      refetch();
      handleSnackBar('success', 'Общее количество участников успешно обновлена');
    } catch (error) {
      handleSnackBar('error', getMessage(error.message));
    }
  };
  const fetchTeamPeopleData = (team: any) =>
    new Promise((resolve) => {
      refetchTeamPeopleData({
        id: team?.id,
      }).then((response) =>
        resolve(
          response?.data?.aggregateParticipantsApplicationByTeamEvents?.rows?.map((r) => ({
            ...r,
            region: team?.dirRegion?.label,
            university: team?.university?.label || '',
          })),
        ),
      );
    });

  const onDateFormSubmit = async (values) => {
    setOpenDateDialog(false);
    setIsGeneratingDoc(true);
    const rows: any =
      dirNationalTeams && dirNationalTeams.length > 0
        ? await Promise.all(
            _.sortBy(dirNationalTeams, ['dirRegion.label'])
              ?.filter(
                (team) =>
                  !team?.archive && team?.statuses?.find((s) => s?.isActive && s?.value?.id === 4) !== undefined,
              )
              .map((team) => fetchTeamPeopleData(team)),
          )
        : [];

    setIsGeneratingDoc(false);

    fetchMandatoryReport({
      rows,
      event,
      date: values?.date,
      isUniverciad: isUniverciad && _.flattenDeep(rows).find((r: any) => r.university) !== undefined,
    });
  };

  const onGenerateTeamsParticipantReports = () => {
    const result: any[] = [];
    const filteredTeams = dirNationalTeams?.filter((t) => !t.archive);
    if (filteredTeams) {
      for (const team of filteredTeams) {
        const { countMen, countWomen, specialistsCount, medicsCount, delegationHeadsCount, trainersCount } = teamCount(
          team as any,
        );
        result.push({
          ...team,
          region: team.dirRegion.label,
          countMen,
          countWomen,
          specialistsCount,
          medicsCount,
          delegationHeadsCount,
          trainersCount,
          totalQuota: (team.athleteTotalQuota || 0) + team.manTeamQuota + team.womanTeamQuota + team.supportTeamQuota,
          factSupport: specialistsCount + medicsCount + trainersCount + delegationHeadsCount,
          factTotal: specialistsCount + medicsCount + trainersCount + delegationHeadsCount + countMen + countWomen,
        });
      }

      fetchParticipantsGeneralReport({
        data: _.sortBy(result, ['region']),
        fileName: 'Общая информация о составе участников',
        isUniverciad,
      });
    }
  };

  const onSubmit = async (values: formState) => {
    const {
      athletesCall,
      point,
      score,
      manTeamQuota,
      womanTeamQuota,
      supportTeamQuota,
      dirRegion,
      emailRecipients,
      athleteQuotaByGender,
      athleteTotalQuota,
      university,
    } = values;

    setIsSubmitting(true);

    if (!isUniversityEvent && regionList?.includes(dirRegion?.value)) {
      handleSnackBar('error', 'сборная данного региона уже добавлена в Мероприятие');
      return;
    }

    try {
      if (currentRequest === 'update') {
        await updateEvent({
          variables: {
            data: {
              dirNationalTeams: {
                update: [
                  {
                    where: {
                      id: teamId,
                    },
                    data: {
                      athletesCall: athletesCall || null,
                      point: (point && parseInt(point, 10)) || null,
                      score: (score && parseInt(score, 10)) || null,
                      manTeamQuota: (manTeamQuota && parseInt(manTeamQuota, 10)) || 0,
                      womanTeamQuota: (womanTeamQuota && parseInt(womanTeamQuota, 10)) || 0,
                      supportTeamQuota: (supportTeamQuota && parseInt(supportTeamQuota, 10)) || 0,
                      athleteQuotaByGender: athleteQuotaByGender === 'true',
                      athleteTotalQuota: (athleteTotalQuota && parseInt(athleteTotalQuota, 10)) || 0,
                    },
                  },
                ],
              },
            },
            id,
          },
        });
        setOpenDialog(false);
        handleSnackBar('success', 'Информация успешно обновлена');
        reset(defaultState);
        setTeamId('');
        setCurrentRequest('');
        setIsSubmitting(false);
        refetch();
        return;
      }
      await updateEvent({
        variables: {
          data: {
            dirNationalTeams: {
              create: [
                {
                  athletesCall: athletesCall || null,
                  manTeamQuota: (manTeamQuota && parseInt(manTeamQuota, 10)) || 0,
                  womanTeamQuota: (womanTeamQuota && parseInt(womanTeamQuota, 10)) || 0,
                  supportTeamQuota: (supportTeamQuota && parseInt(supportTeamQuota, 10)) || 0,
                  athleteQuotaByGender: athleteQuotaByGender === 'true',
                  athleteTotalQuota: (athleteTotalQuota && parseInt(athleteTotalQuota, 10)) || 0,
                  statuses: { create: [{ value: { connect: { id: 5 } } }] },
                  status: { connect: { id: 1 } },
                  dirRegion: {
                    connect: {
                      id: dirRegion?.value,
                    },
                  },
                  ...(university && {
                    university: {
                      connect: {
                        id: university?.value,
                      },
                    },
                  }),
                  emailRecipients: {
                    create: emailRecipients?.map((e) => ({ email: e.value })),
                  },
                },
              ],
            },
          },
          id,
        },
      });
      setOpenDialog(false);
      handleSnackBar('success', 'Сборная успешно создана');
      reset(defaultState);
      setIsSubmitting(false);
      refetch();
    } catch (error) {
      handleSnackBar('error', getMessage(error.message));
    }
  };

  const sortedTeams = useMemo(() => _.sortBy(dirNationalTeams, ['score', 'dirRegion.label']), [dirNationalTeams]);
  const archivedTeams = useMemo(
    () =>
      _.sortBy(
        data?.dirSportingEvent?.dirNationalTeams?.filter((t) => t?.archive),
        ['score', 'dirRegion.label'],
      ),
    [data],
  );

  if (loading) return <LinearProgress variant="query" color="primary" />;

  return (
    <div>
      <Grid style={{ marginBottom: '1rem' }} container justify="space-between" alignItems="center" spacing={2}>
        <Grid item container md={3} style={{ paddingLeft: '0.6rem' }}>
          <form onSubmit={handleSecondSubmit(onUpdateMembersQuota)} style={{ width: '100%' }}>
            <Grid item container alignItems="center" spacing={2}>
              <Grid item>
                <Input
                  label="Общее количество участников"
                  control={secondFormControl}
                  error={!!secondFormError['membersQuota']}
                  name="membersQuota"
                  disabled={readonly}
                  style={{ width: 230 }}
                />
              </Grid>

              <Grid item>
                <Button
                  variant="outlined"
                  color="primary"
                  size="small"
                  startIcon={<SaveIcon />}
                  type="submit"
                  disabled={!secondFormState.isDirty}
                >
                  Сохранить
                </Button>
              </Grid>
            </Grid>
          </form>
          <Grid item container>
            <span className={classes.title}>Фактическое количество:</span>&nbsp;
            {factQuota}
          </Grid>
        </Grid>
        <Grid item md={9}>
          <Grid item container spacing={1}>
            <Grid item>
              <Button
                style={{ margin: '0 5px 5px 0' }}
                size="small"
                variant="contained"
                color="primary"
                onClick={onGenerateTeamsParticipantReports}
              >
                ВЫГРУЗИТЬ ОБЩУЮ ИНФОРМАЦИЮ О СОСТАВЕ УЧАСТНИКОВ
              </Button>
            </Grid>
            <Grid item>
              <Button
                style={{ margin: '0 5px 5px 0' }}
                size="small"
                variant="contained"
                color="primary"
                onClick={() => setOpenDateDialog(true)}
              >
                Скачать Предварительный протокол мандатной комиссии
              </Button>
            </Grid>
            <Grid item>
              <Link href={`${DOWNLOAD_LINK}/reports/sporting-event-quota/${id}`}>
                <Button size="small" variant="contained" color="primary">
                  Выгрузить квоту по участникам
                </Button>
              </Link>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid container justify="flex-end">
        <Grid item md={6}>
          <EventProtocolDoc readonly={readonly} id={id} />
        </Grid>
      </Grid>

      <TeamInfoDocs id={id!} />
      <AthleteDocuments />

      {!readonly && (
        <div>
          <Tooltip title="добавить" placement="top-start">
            <IconButton
              color="primary"
              onClick={() => {
                setOpenDialog(true);
              }}
            >
              <AddBoxIcon />
            </IconButton>
          </Tooltip>
        </div>
      )}
      {/*{dirNationalTeams && dirNationalTeams?.length > 0 && (<TeamInfoRegionTable rows={dirNationalTeams} refetch={refetch} />)}*/}

      {dirNationalTeams && dirNationalTeams?.length > 0 && (
        <TableContainer component={Paper} elevation={1}>
          <Table aria-label="team table">
            <TableHead>
              <TableRow>
                <TableCell>Регион</TableCell>
                <TableCell>ВУЗ</TableCell>
                <TableCell>Статус сборной</TableCell>
                <TableCell>Заявка на участие</TableCell>
                {/* <TableCell className={classes.tableEmail}>Email</TableCell> */}
                <TableCell>Квота муж. + жен.</TableCell>
                <TableCell>Квота муж.</TableCell>
                <TableCell>Квота жен.</TableCell>
                <TableCell>Квота на сопр-ние</TableCell>
                <TableCell>Квота итого</TableCell>
                <TableCell>Факт муж</TableCell>
                <TableCell>Факт жен</TableCell>
                <TableCell>Факт сопр-ние</TableCell>
                <TableCell>Факт итого</TableCell>
                <TableCell>Место</TableCell>
                <TableCell>Кол-во очков</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {[...sortedTeams, ...archivedTeams].map((team: any, index: number) => {
                const {
                  countMen,
                  countWomen,
                  specialistsCount,
                  medicsCount,
                  delegationHeadsCount,
                  trainersCount,
                } = teamCount(team);

                return (
                  <TeamPeopleAccordion
                    team={team}
                    readonly={readonly}
                    hasHead={index === 0}
                    isNotVerified={!teamIsVerified(team)}
                    status={status}
                  >
                    <>
                      <TableCell component="th" scope="row">
                        {isGskReferee ? (
                          teamIsVerified(team) ? (
                            getTeamRegion(team)
                          ) : (
                            <span> {team.dirRegion.label || '~'}</span>
                          )
                        ) : (
                          getTeamRegion(team)
                        )}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {team?.university?.label}
                      </TableCell>
                      <TableCell>{team?.statuses?.find((s: any) => s.isActive)?.value?.name || '~'}</TableCell>
                      <TableCell>
                        <Link href={`${DOWNLOAD_LINK}/reports/sporting-event-participants-application/${team.id}`}>
                          <IconButton size="small" color="primary">
                            <DownloadIcon />
                          </IconButton>
                        </Link>
                      </TableCell>
                      {/* <TableCell className={classes.tableEmail}>
                            {team.emailRecipients?.map((e: any) => e.label).join(', ') || '~'}
                          </TableCell> */}
                      <TableCell>{team.athleteTotalQuota || '~'}</TableCell>
                      <TableCell>{team.manTeamQuota || 0}</TableCell>
                      <TableCell>{team.womanTeamQuota || 0}</TableCell>
                      <TableCell>{team.supportTeamQuota || 0}</TableCell>
                      <TableCell>
                        {team.athleteTotalQuota + team.manTeamQuota + team.womanTeamQuota + team.supportTeamQuota ||
                          '~'}
                      </TableCell>
                      <TableCell>{countMen}</TableCell>
                      <TableCell>{countWomen}</TableCell>
                      <TableCell>{specialistsCount + medicsCount + trainersCount + delegationHeadsCount}</TableCell>
                      <TableCell>
                        {specialistsCount + medicsCount + trainersCount + delegationHeadsCount + countMen + countWomen}
                      </TableCell>

                      <TableCell>{team.score || ''}</TableCell>
                      <TableCell>{team.point || ''}</TableCell>
                      <TableCell>
                        {!readonly && (
                          <div className={classes.row} style={{ alignItems: 'center' }}>
                            {!team?.archive && (
                              <Tooltip title="изменить" placement="top-start">
                                <IconButton
                                  className={classes.btn}
                                  color="primary"
                                  onClick={(event) => {
                                    event.stopPropagation();
                                    setTeamId(team.id);
                                    reset({
                                      ...team,
                                      athleteQuotaByGender: team?.athleteQuotaByGender ? 'true' : 'false',
                                    });
                                    setCurrentRequest('update');
                                    setOpenDialog(true);
                                  }}
                                >
                                  <EditIcon className={classes.icon} />
                                </IconButton>
                              </Tooltip>
                            )}

                            <ArchiveComponent
                              id={team.id}
                              archiveStatus={team.archive}
                              canUnArchive
                              unarchive={unarchive}
                              refetch={refetch}
                              archive={archive}
                            />
                          </div>
                        )}
                      </TableCell>
                      <TableCell />
                    </>
                  </TeamPeopleAccordion>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <Dialog
        onClose={() => {
          setOpenDialog(false);
          reset(defaultState);
          setTeamId('');
          setCurrentRequest('');
        }}
        aria-labelledby="add-calendar-dialog"
        open={openDialog}
      >
        <DialogTitle id="add-calendar-dialog">
          {(currentRequest === 'update' && 'Изменить сборную') || 'Новая сборная'}
        </DialogTitle>
        <div style={{ padding: '1.5rem' }}>
          <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
            <Grid container spacing={2}>
              {currentRequest !== 'update' && (
                <>
                  <Grid item xs={12}>
                    <Regions
                      label="Регион"
                      control={control}
                      error={!!errors['dirRegion']}
                      name="dirRegion"
                      rules={{ required: true }}
                      regionList={regionList}
                      useLabelElement
                    />
                  </Grid>

                  {region && isUniversityEvent && (
                    <Grid item xs={12} key={region?.value}>
                      <UniversitiesByRegion
                        label="ВУЗ"
                        control={control}
                        error={!!errors['university']}
                        name="university"
                        rules={{ required: true }}
                        useLabelElement
                        regionId={region?.value}
                        universityList={universityList}
                      />
                    </Grid>
                  )}
                  {region && (
                    <Grid item xs={12} key={university?.value}>
                      <TeamsPossibleRoiv
                        label={
                          isUniversityEvent ? 'Специалист ВУЗа для уведомления' : 'Специалист РОИВ для уведомления'
                        }
                        control={control}
                        error={!!errors['emailRecipients']}
                        name="emailRecipients"
                        multiple
                        isUniversityRoiv={isUniversityEvent}
                        organizationId={university?.value}
                        regionId={region?.value}
                        defaultEmails={emailRecipients?.map((e) => e.label) || []}
                      />
                    </Grid>
                  )}
                </>
              )}
              <Grid item container>
                <Radios
                  label="Вид квоты"
                  data={[
                    {
                      value: 'false',
                      label: 'Вызов общий',
                    },
                    {
                      value: 'true',
                      label: 'Вызов с делением по полу',
                    },
                  ]}
                  control={control}
                  error={!!errors['athleteQuotaByGender']}
                  name="athleteQuotaByGender"
                  rules={{ required: true }}
                />
              </Grid>
              <Grid item container justify="space-between" spacing={1}>
                {athleteQuotaByGender === 'false' ? (
                  <>
                    <Grid item container xs={4}>
                      <Input
                        label="Квота муж.+ жен."
                        control={control}
                        error={!!errors['athleteTotalQuota']}
                        name="athleteTotalQuota"
                        rules={{ required: true }}
                      />
                    </Grid>
                  </>
                ) : (
                  <>
                    <Grid item container xs={4}>
                      <Input
                        label="Квота муж."
                        control={control}
                        error={!!errors['manTeamQuota']}
                        name="manTeamQuota"
                        rules={{ required: true }}
                      />
                    </Grid>
                    <Grid item container xs={4}>
                      <Input
                        label="Квота жен."
                        control={control}
                        error={!!errors['womanTeamQuota']}
                        name="womanTeamQuota"
                        rules={{ required: true }}
                      />
                    </Grid>
                  </>
                )}

                <Grid item container xs={4}>
                  <Input
                    label="Квота на сопровожд."
                    control={control}
                    error={!!errors['supportTeamQuota']}
                    name="supportTeamQuota"
                    rules={{ required: true }}
                  />
                </Grid>
              </Grid>
              {currentRequest === 'update' && (
                <Grid item container justify="space-between">
                  <Grid item xs={4}>
                    <Input label="Место" control={control} error={!!errors['score']} name="score" />
                  </Grid>
                  <Grid item xs={4}>
                    <Input label="Кол-во очков" control={control} error={!!errors['point']} name="point" />
                  </Grid>
                </Grid>
              )}

              <Grid item container justify="flex-end">
                <Button
                  variant="outlined"
                  color="primary"
                  size="small"
                  startIcon={<SaveIcon />}
                  type="submit"
                  disabled={!formState.isDirty || isSubmitting}
                >
                  {(currentRequest === 'update' && 'Сохранить') || 'Добавить'}
                </Button>
              </Grid>
            </Grid>
          </form>
        </div>
      </Dialog>
      <Dialog open={openDateDialog} onClose={() => setOpenDateDialog(false)}>
        <DialogTitle>Протокол мандатной комиссии</DialogTitle>
        <DialogContent>
          <form onSubmit={dateFormSubmit(onDateFormSubmit)} style={{ width: '100%' }}>
            <Grid container spacing={2}>
              <DatePicker label="на дату" control={dateFormControl} error={!!dateFormErrors['date']} name="date" />
              <Grid item container justify="space-between">
                <Button
                  variant="outlined"
                  color="secondary"
                  size="small"
                  type="button"
                  onClick={() => setOpenDateDialog(false)}
                  disabled={!dateFormState.isDirty}
                >
                  Закрыть
                </Button>
                <Button variant="outlined" color="primary" size="small" type="submit" disabled={!dateFormState.isDirty}>
                  Скачать
                </Button>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
      {isGeneratingDoc && <LoadingWall label="отчет формируется, не закрывайте страницу" />}
    </div>
  );
};

export default TeamInfo;
