import {
  UploadFileMutationHookResult,
  useUploadFileMutation,
  usePersonPDocsQuery,
  UpdatePersonDocMutationHookResult,
  useUpdatePersonDocMutation,
} from '../../../api';
import React, { FC, useEffect, useState, useMemo } from 'react';
import { Button, Grid, Link, Tooltip, IconButton, LinearProgress } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import { ContentWrapperWidget } from '../../layouts';
import { useSnackbar } from 'notistack';
import getMessage from '../../../messages';
import { makeStyles } from '@material-ui/core/styles';
import DescriptionIcon from '@material-ui/icons/Description';
import DeleteIcon from '@material-ui/icons/Delete';
import _ from 'lodash';
import ConfirmDialog from '../../ConfirmDialog';

const useStyles = makeStyles({
  title: {
    fontWeight: 500,
    width: 270,
    display: 'inline-block',
  },
  link: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'flex-end',
  },
  verticalSpace: {
    paddingBottom: 15,
  },
  verticalSpaceMarge: {
    marginBottom: 15,
  },
  divider: {
    height: 1,
    width: '100%',
    marginBottom: '1rem',
  },
  btn: {
    padding: '5px 5px',
    minWidth: '15px',
  },
  tinyIcon: {
    fontSize: 18,
  },
  icon: {
    fontSize: 20,
  },
  input: {
    display: 'none',
  },
});

const RefereePassport: FC<{ readonly?: boolean; id?: string }> = ({ readonly, id }) => {
  const DOWNLOAD_LINK = process.env.REACT_APP_BACKEND_URI?.replace('/graphql', '');
  const classes = useStyles();
  const { loading, data, refetch } = usePersonPDocsQuery({ variables: { id }, fetchPolicy: 'no-cache' });
  const { enqueueSnackbar } = useSnackbar();

  const doc1 = useMemo(() => data?.dirPerson?.doc1 || null, [data]);
  const doc2 = useMemo(() => data?.dirPerson?.doc2 || null, [data]);
  const doc3 = useMemo(() => data?.dirPerson?.doc3 || null, [data]);

  const handleSnackBar = (variant: 'default' | 'error' | 'success' | 'warning' | 'info' | undefined, message: string) =>
    enqueueSnackbar(message, { variant });

  const [deleteItem, setDeleteItem] = useState<any>(null);
  const [uploadDocumentFile]: UploadFileMutationHookResult = useUploadFileMutation();
  const [update]: UpdatePersonDocMutationHookResult = useUpdatePersonDocMutation();

  const handleAddPFile = async (evt: any, index: number) => {
    const file = _.get(evt, 'target.files[0]', null);
    try {
      if (id !== 'new' && file) {
        uploadDocumentFile({
          variables: {
            file,
          },
        }).then(async (resp: any) => {
          const {
            uploadFile: { id: fileId },
          } = resp.data;
          await update({
            variables: {
              data: {
                ['doc' + index]: {
                  connect: { id: fileId },
                },
              },
              id,
            },
          });
          handleSnackBar('success', `Документ "${file?.name}" успешно добавлен`);
          refetch?.();
        });
      }
    } catch (e) {
      enqueueSnackbar(getMessage(e.message), { variant: 'error' });
    }
  };

  const handleDeletePFile = async (item: any) => {
    try {
      if (item) {
        await update({
          variables: {
            data: {
              ['doc' + item.index]: {
                disconnect: true,
              },
            },
            id,
          },
        });
        setDeleteItem(null);
        handleSnackBar('success', `Документ "${item?.name}" успешно удален`);
        refetch();
      }
    } catch (e) {
      enqueueSnackbar(getMessage(e.message), { variant: 'error' });
    }
  };

  const DocRow = ({ title, item, index }: { title: string; item: any; index: number }) => (
    <Grid item container alignItems="center" className={classes.verticalSpaceMarge} spacing={2}>
      {item?.path ? (
        <>
          <Grid item>
            <Link className={`${classes.link} ${classes.title}`} target="_blank" href={`${DOWNLOAD_LINK}${item?.path}`}>
              <DescriptionIcon />
              <span style={{ marginLeft: '0.5rem' }}>{title}</span>
            </Link>
          </Grid>
          <Grid item>
            <Tooltip title="удалить" placement="top-start">
              <IconButton color="secondary" onClick={() => setDeleteItem({ ...item, name: title, index })}>
                <DeleteIcon className={classes.icon} />
              </IconButton>
            </Tooltip>
          </Grid>
        </>
      ) : (
        <>
          <Grid item>
            <span className={classes.title}>{title}:</span>
          </Grid>
          <Grid item>
            <label htmlFor="p-file">
              <input
                accept="image/*, .pdf, .jpeg, .png, .jpg"
                className={classes.input}
                id="p-file"
                type="file"
                onChange={(evt: any) => handleAddPFile(evt, index)}
              />
              <Button variant="contained" component="span" size="small" color="primary">
                добавить
              </Button>
            </label>
          </Grid>
        </>
      )}
    </Grid>
  );

  if (loading) return <LinearProgress />;

  return (
    <ContentWrapperWidget>
      <Grid container>
        <Grid item xs={12}>
          <h4 style={{ marginTop: 0 }}>
            <strong>PDF/Фото</strong> (фотографии должны быть качественные, необрезанные):
          </h4>
        </Grid>
        <DocRow item={doc1} title="Разворот 1-2 страницы" index={1} />
        <DocRow item={doc2} title="Разворот с пропиской" index={2} />
        <DocRow item={doc3} title="Разворот с отметкой о смене фамилии" index={3} />
      </Grid>
      {deleteItem && (
        <ConfirmDialog
          title={`Удаление документа`}
          text={`Вы уверены что хотите удалить документ : ${deleteItem?.name}`}
          btnVariant="outlined"
          open={true}
          saveBtnText="удалить"
          cancelBtnText="отмена"
          saveBtnColor="secondary"
          cancelBtnColor="primary"
          onCancel={() => setDeleteItem(null)}
          onSave={() => handleDeletePFile(deleteItem)}
        />
      )}
    </ContentWrapperWidget>
  );
};

export default RefereePassport;
