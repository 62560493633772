import React, { FC, useEffect, useMemo, useState } from 'react';
import {
  Grid,
  Button,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  Input as InputMui,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SaveIcon from '@material-ui/icons/Save';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import LinearProgress from '@material-ui/core/LinearProgress';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { DatePicker, Input, Select } from '../../Inputs';
import { detectedChanges } from '../../../utils/compareSportingEvents';
import getMessage from '../../../messages';
import _ from 'lodash';
import {
  Scalars,
  useSportingEventQuery,
  UpdateSportingEventMutationHookResult,
  useUpdateSportingEventMutation,
  SportingEventDocument,
  Maybe,
  ClassifierValue,
} from '../../../api';
import FinancingTypes from '../../../module/FinancingTypes';
import moment from 'moment';
import { Alert } from '@material-ui/lab';

const useStyles = makeStyles({
  alignCenter: {
    alignItems: 'center',
  },
  title: {
    fontWeight: 500,
  },
  btn: {
    padding: '5px 5px',
    minWidth: '15px',
  },
  tinyIcon: {
    fontSize: 18,
  },
  icon: {
    fontSize: 20,
  },
});

const months = [
  { value: 0, label: ' ' },
  { value: 1, label: 'Январь' },
  { value: 2, label: 'Февраль' },
  { value: 3, label: 'Март' },
  { value: 4, label: 'Апрель' },
  { value: 5, label: 'Май' },
  { value: 6, label: 'Июнь' },
  { value: 7, label: 'Июль' },
  { value: 8, label: 'Август' },
  { value: 9, label: 'Сентябрь' },
  { value: 10, label: 'Октябрь' },
  { value: 11, label: 'Ноябрь' },
  { value: 12, label: 'Декабрь' },
];

const years = [
  { value: 2021, label: '2021' },
  { value: 2020, label: '2020' },
  { value: 2019, label: '2019' },
  { value: 2018, label: '2018' },
  { value: 2017, label: '2017' },
  { value: 2016, label: '2016' },
];

interface formState {
  planRUB?: Scalars['Int'] | null;
  planRUBperPerson?: Scalars['Int'] | null;
  currentPlanRUB?: Scalars['Int'] | null;
  currentPlanRUBperPerson?: Scalars['Int'] | null;
  factRUB?: Scalars['Int'] | null;
  factRUBperPerson?: Scalars['Int'] | null;
  planUSD?: Scalars['Int'] | null;
  planUSDperPerson?: Scalars['Int'] | null;
  currentPlanUSD?: Scalars['Int'] | null;
  currentPlanUSDperPerson?: Scalars['Int'] | null;
  factUSD?: Scalars['Int'] | null;
  factUSDperPerson?: Scalars['Int'] | null;
  planDate?: Date;
  currentPlanDate?: Date;
  factDate?: Date;
  year?: any;
  month?: any;
  clsFinancingTypes: Maybe<Array<ClassifierValue>>;
}

const Costs: FC<{ readonly?: boolean; id?: string }> = ({ readonly, id }) => {
  const classes = useStyles();

  const { loading, data } = useSportingEventQuery({ fetchPolicy: 'no-cache', variables: { id } });
  const [update]: UpdateSportingEventMutationHookResult = useUpdateSportingEventMutation({
    update: (proxy, { data }) => {
      proxy.writeQuery({
        query: SportingEventDocument,
        data: {
          ...data?.updateDirSportingEvent,
        },
      });
    },
  });
  const [values, setValues] = useState<any>(data?.dirSportingEvent?.cost || {});

  const alerts = useMemo(() => {
    const alert = [];
    if (!data?.dirSportingEvent?.quantity?.participantAthletesTotal && !data?.dirSportingEvent?.quantity?.staffTotal) {
      if (!data?.dirSportingEvent?.quantity?.participantAthletesTotal) {
        alert.push({
          title: 'Нужно заполнить поле количество атлетов в разделе Участники',
        });
      }

      if (!data?.dirSportingEvent?.quantity?.staffTotal) {
        alert.push({
          title: 'Нужно заполнить поле количество обслуживающего персонала в разделе Участники',
        });
      }
    }
    setValues(data?.dirSportingEvent?.cost || {});
    return alert;
  }, [data]);

  const countPersons =
    (data?.dirSportingEvent?.quantity?.participantAthletesTotal || 0) +
    (data?.dirSportingEvent?.quantity?.staffTotal || 0);
  const diffDay = moment(
    data?.dirSportingEvent?.isRussia
      ? moment(data?.dirSportingEvent?.endDate).add(1, 'day')
      : data?.dirSportingEvent?.endDate,
  ).diff(moment(data?.dirSportingEvent?.startDate), 'days');
  const { enqueueSnackbar } = useSnackbar();

  const handleSnackBar = (variant: 'default' | 'error' | 'success' | 'warning' | 'info' | undefined, message: string) =>
    enqueueSnackbar(message, { variant });

  const { handleSubmit, control, errors, reset, formState, watch, setValue } = useForm({
    defaultValues: {
      ...data?.dirSportingEvent,
      year: _.find(years, function (o) {
        return o.value === data?.dirSportingEvent?.cost?.year;
      }),
      month: _.find(months, function (o) {
        return o.value === data?.dirSportingEvent?.cost?.month;
      }),
    } as formState,
  });

  const onSubmit = async (val: formState) => {
    const {
      planRUB,
      planRUBperPerson,
      currentPlanRUB,
      currentPlanRUBperPerson,
      factRUB,
      factRUBperPerson,
      planUSD,
      planUSDperPerson,
      currentPlanUSD,
      currentPlanUSDperPerson,
      factUSD,
      factUSDperPerson,
      planDate,
      currentPlanDate,
      factDate,
      year,
      month,
      clsFinancingTypes,
    } = val;
    try {
      await update({
        variables: {
          data: {
            cost: {
              [data?.dirSportingEvent?.cost?.id != undefined ? 'update' : 'create']: {
                planRUB: values.planRUB != null ? Number(values.planRUB) : 0,
                planRUBperPerson: values.planRUBperPerson != null ? Number(values.planRUBperPerson) : 0,
                currentPlanRUB: values.currentPlanRUB != null ? Number(values.currentPlanRUB) : 0,
                currentPlanRUBperPerson:
                  values.currentPlanRUBperPerson != null ? Number(values.currentPlanRUBperPerson) : 0,
                factRUB: values.factRUB != null ? Number(values.factRUB) : 0,
                planUSD: values.planUSD != null ? Number(values.planUSD) : 0,
                planUSDperPerson: values.planUSDperPerson != null ? Number(values.planUSDperPerson) : 0,
                currentPlanUSD: values.currentPlanUSD != null ? Number(values.currentPlanUSD) : 0,
                currentPlanUSDperPerson:
                  values.currentPlanUSDperPerson != null ? Number(values.currentPlanUSDperPerson) : 0,
                factUSD: values.factUSD != null ? Number(values.factUSD) : 0,
                factUSDperPerson: values.factUSDperPerson != null ? Number(values.factUSDperPerson) : 0,
                planDate: planDate != null ? planDate : null,
                currentPlanDate: currentPlanDate != null ? currentPlanDate : null,
                factDate: factDate != null ? factDate : null,
                year: year ? Number(year.value) : 0,
                month: month ? Number(month.value) : 0,
                clsFinancingTypes: {
                  connect: values?.clsFinancingTypes?.map((v: any) => ({ id: _.get(v, 'value') })),
                },
              },
            },
          },
          id,
        },
      });

      handleSnackBar('success', 'Требование успешно обновлена');
    } catch (error) {
      handleSnackBar('error', getMessage(error.message));
    }
  };

  const handleChange = (prop: any) => (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist();
    if (prop === 'planRUB' && countPersons > 0) {
      // @ts-ignore
      setValues((prevState: any) => ({
        ...prevState,
        // @ts-ignore
        planRUBperPerson: ((event?.target?.value || 0) / +countPersons / +diffDay).toFixed(2),
      }));
    }
    if (prop === 'planRUBperPerson' && countPersons > 0) {
      setValues((prevState: any) => ({
        ...prevState,
        // @ts-ignore
        planRUB: ((event?.target?.value || 0) * +countPersons * +diffDay).toFixed(2),
      }));
    }
    if (prop === 'currentPlanRUB' && countPersons > 0) {
      // @ts-ignore
      setValues((prevState: any) => ({
        ...prevState,
        // @ts-ignore
        currentPlanRUBperPerson: ((event?.target?.value || 0) / +countPersons / +diffDay).toFixed(2),
      }));
    }
    if (prop === 'currentPlanRUBperPerson' && countPersons > 0) {
      setValues((prevState: any) => ({
        ...prevState,
        // @ts-ignore
        currentPlanRUB: ((event?.target?.value || 0) * +countPersons * +diffDay).toFixed(2),
      }));
    }
    if (prop === 'factRUB' && countPersons > 0) {
      // @ts-ignore
      setValues((prevState: any) => ({
        ...prevState,
        // @ts-ignore
        factRUBperPerson: ((event?.target?.value || 0) / +countPersons / +diffDay).toFixed(2),
      }));
    }
    if (prop === 'factRUBperPerson' && countPersons > 0) {
      setValues((prevState: any) => ({
        ...prevState,
        // @ts-ignore
        factRUB: ((event?.target?.value || 0) * +countPersons * +diffDay).toFixed(2),
      }));
    }

    if (prop === 'planUSD' && countPersons > 0) {
      setValues((prevState: any) => ({
        ...prevState,
        // @ts-ignore
        planUSDperPerson: ((event?.target?.value || 0) / +countPersons / +diffDay).toFixed(2),
      }));
    }
    if (prop === 'planUSDperPerson' && countPersons > 0) {
      setValues((prevState: any) => ({
        ...prevState,
        // @ts-ignore
        planUSD: ((event?.target?.value || 0) * +countPersons * +diffDay).toFixed(2),
      }));
    }
    if (prop === 'currentPlanUSD' && countPersons > 0) {
      setValues((prevState: any) => ({
        ...prevState,
        // @ts-ignore
        currentPlanUSDperPerson: ((event?.target?.value || 0) / +countPersons / +diffDay).toFixed(2),
      }));
    }
    if (prop === 'currentPlanUSDperPerson' && countPersons > 0) {
      setValues((prevState: any) => ({
        ...prevState,
        // @ts-ignore
        currentPlanUSD: ((event?.target?.value || 0) * +countPersons * +diffDay).toFixed(2),
      }));
    }
    if (prop === 'factUSD' && countPersons > 0) {
      setValues((prevState: any) => ({
        ...prevState,
        // @ts-ignore
        factUSDperPerson: ((event?.target?.value || 0) / +countPersons / +diffDay).toFixed(2),
      }));
    }
    if (prop === 'factUSDperPerson' && countPersons > 0) {
      setValues((prevState: any) => ({
        ...prevState,
        // @ts-ignore
        factUSD: ((event?.target?.value || 0) * +countPersons * +diffDay).toFixed(2),
      }));
    }
    setValues((prevState: any) => ({ ...prevState, [prop]: event?.target?.value }));
  };

  useEffect(() => {
    reset({
      ...data?.dirSportingEvent?.cost,
      year: _.find(years, function (o) {
        return o.value === data?.dirSportingEvent?.cost?.year;
      }),
      month: _.find(months, function (o) {
        return o.value === data?.dirSportingEvent?.cost?.month;
      }),
    });
  }, [data, reset]);

  if (loading) return <LinearProgress />;

  const dChanges = detectedChanges(data?.dirSportingEvent).changesArray;
  return (
    <div style={{ paddingTop: '2rem' }}>
      {alerts.map((item, index) => (
        <Alert key={index} style={{ marginBottom: 10 }} severity="warning">
          {item.title}
        </Alert>
      ))}
      <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Select
              label="Месяц"
              data={months}
              control={control}
              error={!!errors['month']}
              name="month"
              disabled={readonly}
            />
          </Grid>
          <Grid item xs={2}>
            <Select
              label="Год"
              data={years}
              control={control}
              error={!!errors['year']}
              name="year"
              disabled={readonly}
            />
          </Grid>
          <Grid item xs={5}>
            <FinancingTypes
              label="Тип финансирования участия"
              control={control}
              error={!!errors['clsFinancingTypes']}
              name="clsFinancingTypes"
              rules={{ required: false }}
              disabled={readonly}
            />
          </Grid>
          {dChanges.includes('cost') && (
            <Grid item>
              <ExpandMoreIcon color="secondary" />
            </Grid>
          )}
        </Grid>
        <TableContainer component={Paper}>
          <Table aria-label="team table">
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>План</TableCell>
                <TableCell>План/чел.</TableCell>
                <TableCell>Тек. План</TableCell>
                <TableCell>Тек. План/чел.</TableCell>
                <TableCell>Факт</TableCell>
                <TableCell>Факт/чел.</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>Рубли</TableCell>
                <TableCell>
                  <InputMui value={values.planRUB} onChange={handleChange('planRUB')} disabled={readonly} />
                </TableCell>
                <TableCell>
                  <InputMui
                    value={values.planRUBperPerson}
                    onChange={handleChange('planRUBperPerson')}
                    disabled={readonly}
                  />
                </TableCell>
                <TableCell>
                  <InputMui
                    value={values.currentPlanRUB}
                    onChange={handleChange('currentPlanRUB')}
                    disabled={readonly}
                  />
                </TableCell>
                <TableCell>
                  <InputMui
                    value={values.currentPlanRUBperPerson}
                    onChange={handleChange('currentPlanRUBperPerson')}
                    disabled={readonly}
                  />
                </TableCell>
                <TableCell>
                  <InputMui value={values.factRUB} onChange={handleChange('factRUB')} disabled={readonly} />
                </TableCell>
                <TableCell>
                  <InputMui
                    value={values.factRUBperPerson}
                    onChange={handleChange('factRUBperPerson')}
                    disabled={readonly}
                  />
                </TableCell>
                <TableCell></TableCell>
              </TableRow>
              {!data?.dirSportingEvent?.isRussia && (
                <TableRow>
                  <TableCell>Доллары</TableCell>
                  <TableCell>
                    <InputMui value={values.planUSD} onChange={handleChange('planUSD')} disabled={readonly} />
                  </TableCell>
                  <TableCell>
                    <InputMui
                      value={values.planUSDperPerson}
                      onChange={handleChange('planUSDperPerson')}
                      disabled={readonly}
                    />
                  </TableCell>
                  <TableCell>
                    <InputMui
                      value={values.currentPlanUSD}
                      onChange={handleChange('currentPlanUSD')}
                      disabled={readonly}
                    />
                  </TableCell>
                  <TableCell>
                    <InputMui
                      value={values.currentPlanUSDperPerson}
                      onChange={handleChange('currentPlanUSDperPerson')}
                      disabled={readonly}
                    />
                  </TableCell>
                  <TableCell>
                    <InputMui value={values.factUSD} onChange={handleChange('factUSD')} disabled={readonly} />
                  </TableCell>
                  <TableCell>
                    <InputMui
                      value={values.factUSDperPerson}
                      onChange={handleChange('factUSDperPerson')}
                      disabled={readonly}
                    />
                  </TableCell>
                  <TableCell></TableCell>
                </TableRow>
              )}
              <TableRow>
                <TableCell>Дата</TableCell>
                <TableCell>
                  <DatePicker
                    label=""
                    control={control}
                    error={!!errors['planDate']}
                    name="planDate"
                    rules={{ required: false }}
                    disabled={readonly}
                  />
                </TableCell>
                <TableCell></TableCell>
                <TableCell>
                  <DatePicker
                    label=""
                    control={control}
                    error={!!errors['currentPlanDate']}
                    name="currentPlanDate"
                    rules={{ required: false }}
                    disabled={readonly}
                  />
                </TableCell>
                <TableCell></TableCell>
                <TableCell>
                  <DatePicker
                    label=""
                    control={control}
                    error={!!errors['factDate']}
                    name="factDate"
                    rules={{ required: false }}
                    disabled={readonly}
                  />
                </TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <Grid item container justify="flex-end">
          <Button variant="outlined" color="primary" size="small" startIcon={<SaveIcon />} type="submit">
            Сохранить
          </Button>
        </Grid>
      </form>
    </div>
  );
};

export default Costs;
