import React, { FC, useState } from 'react';
import { Button, IconButton, Grid, Typography, Tooltip } from '@material-ui/core';
import { validateSnils } from '../../../../utils/validation';
import ClearIcon from '@material-ui/icons/Clear';
import { useForm } from 'react-hook-form';
import { MaskedInput } from '../../../Inputs';
import getMessage from '../../../../messages';
import SaveIcon from '@material-ui/icons/Save';
import EditIcon from '@material-ui/icons/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { useSnackbar } from 'notistack';
import { UpdatePersonSnilsMutationHookResult, useUpdatePersonSnilsMutation, usePersonByIdQuery } from '../../../../api';
import _ from 'lodash';

interface formState {
  snils?: string | null;
}

const SnilsBox: FC<{ readonly?: boolean; id: string }> = ({ readonly, id }) => {
  const [isEditing, setIsEditing] = useState(false);
  const { data, refetch } = usePersonByIdQuery({ variables: { id } });
  const [updatePersonSnils]: UpdatePersonSnilsMutationHookResult = useUpdatePersonSnilsMutation();

  const { enqueueSnackbar } = useSnackbar();

  const handleSnackBar = (variant: 'default' | 'error' | 'success' | 'warning' | 'info' | undefined, message: string) =>
    enqueueSnackbar(message, { variant });

  const defaultState: formState = {
    snils: data?.dirPerson?.snils,
  };

  const onHandleCancel = () => {
    setIsEditing(false);
    reset({});
  };

  const { handleSubmit, control, errors, reset, formState } = useForm({
    defaultValues: defaultState,
  });

  const onSubmit = async (values: formState) => {
    const { snils } = values;
    const { isOk, error } = validateSnils(snils?.replace(/-| /g, ''));

    if (isOk) {
      try {
        await updatePersonSnils({
          variables: {
            snils: snils || '',
            id,
          },
        });
        refetch();
        onHandleCancel();
        handleSnackBar('success', 'снилс успешно обновлена');
      } catch (e) {
        handleSnackBar('error', getMessage(e.message));
      }
    } else if (error) {
      handleSnackBar('error', 'Указан некорректный СНИЛС');
    }
  };

  const onDeleteSnils = async () => {
    try {
      await updatePersonSnils({
        variables: {
          snils: '',
          id,
        },
      });
      refetch();
      onHandleCancel();
      handleSnackBar('success', 'снилс успешно удалена');
    } catch (e) {
      handleSnackBar('error', getMessage(e.message));
    }
  };

  return (
    <form onSubmit={handleSubmit((values) => onSubmit(values))} style={{ width: '100%' }}>
      {!readonly && isEditing ? (
        <Grid container style={{ marginBottom: 15 }} alignItems="flex-end" spacing={2}>
          <Grid item md={3} xs={12}>
            <MaskedInput
              name="snils"
              label="снилс"
              control={control}
              error={!!errors['snils']}
              mask={[/\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, ' ', /\d/, /\d/]}
              rules={{ required: true, pattern: /^\d{3}-\d{3}-\d{3} \d{2}$/ }}
            />
          </Grid>

          <Grid item>
            <Button
              variant="outlined"
              color="secondary"
              size="small"
              startIcon={<ClearIcon />}
              type="button"
              onClick={onHandleCancel}
            >
              Отменить
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="outlined"
              color="primary"
              size="small"
              startIcon={<SaveIcon />}
              type="submit"
              disabled={!formState.isDirty}
            >
              Сохранить
            </Button>
          </Grid>
        </Grid>
      ) : (
        <Grid container style={{ marginBottom: 10 }} alignItems="center" spacing={2}>
          <Grid item>
            <Typography>
              <strong>СНИЛС:</strong>
              {` ${data?.dirPerson?.snils || '~'}`}
            </Typography>
          </Grid>
          {!readonly && (
            <Grid item>
              <Tooltip title="редактировать" placement="top">
                <IconButton
                  color="primary"
                  type="button"
                  onClick={() => {
                    reset({ snils: data?.dirPerson?.snils });
                    setIsEditing(true);
                  }}
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          )}
          {!readonly && data?.dirPerson?.snils && (
            <Grid item>
              <Tooltip title="удалить" placement="top">
                <IconButton color="secondary" type="button" onClick={onDeleteSnils}>
                  <DeleteForeverIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          )}
        </Grid>
      )}
    </form>
  );
};

export default SnilsBox;
