import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@material-ui/core';
import {
  OrganizationTypeSportArchiveMutationHookResult,
  OrganizationTypeSportUnArchiveMutationHookResult,
  useOrganizationTypeSportArchiveMutation,
  useOrganizationTypeSportUnArchiveMutation,
} from '../../../../api';
import React, { FC, Fragment, useState } from 'react';

import ArchiveComponent from '../../../Dialogs/Archive/Archive';
import DateBox from './DateBox';
import DisciplineGroupsBox from './DisciplineGroupsBox';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Grid } from '@material-ui/core';
import TrainersAccordion from './TrainersAccordion';
import _ from 'lodash';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  row: {
    display: 'flex',
  },
  btn: {
    padding: '5px 5px',
    minWidth: '15px',
  },
  tinyIcon: {
    fontSize: 18,
  },
  icon: {
    fontSize: 20,
  },
  tableHead: {
    fontWeight: 'bold',
  },
  header: {
    fontWeight: 600,
    fontSize: 15,
    letterSpacing: 1.2,
  },
});

const SportAccordion: FC<{ readonly?: boolean; sport: any; orgId: string; regionId?: string; onUpdate: Function }> = ({
  readonly,
  sport,
  orgId,
  regionId,
  onUpdate,
}) => {
  const classes = useStyles();

  const [archive]: OrganizationTypeSportArchiveMutationHookResult = useOrganizationTypeSportArchiveMutation();
  const [unarchive]: OrganizationTypeSportUnArchiveMutationHookResult = useOrganizationTypeSportUnArchiveMutation();

  return (
    <Accordion key={sport.id} style={{ width: '100%', marginBottom: 15 }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`${sport.dirSport?.label}-content`}
        id={`${sport.dirSport?.label}-header`}
        style={{ backgroundColor: (sport?.archive && 'lightgrey') || 'inherit' }}
      >
        <Grid container spacing={2} justify="space-between" alignItems="center">
          <Grid item>
            <Typography className={classes.header} color="primary">
              {sport.dirSport.label}
            </Typography>
          </Grid>
          <Grid item onClick={(e) => e.stopPropagation()}>
            {!readonly && (
              <ArchiveComponent
                id={sport?.id}
                archiveStatus={sport?.archive}
                canUnArchive
                unarchive={unarchive}
                refetch={onUpdate}
                archive={archive}
              />
            )}
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails style={{ flexDirection: 'column' }}>
        <DateBox sport={sport} onUpdate={onUpdate} readonly={readonly || sport.archive} />
        <DisciplineGroupsBox sport={sport} classes={classes} onUpdate={onUpdate} readOnly={readonly || sport.archive} />
        <TrainersAccordion id={orgId} regionId={regionId} sportId={sport?.dirSport?.value} />
      </AccordionDetails>
    </Accordion>
  );
};

export default SportAccordion;
