import exceljs from 'exceljs';
import { AthleteCompetitionResultFragment, CompetitionsResultsFragmentFragment, DirAthlete } from '../../../api';
import { saveAs } from 'file-saver';
import moment from 'moment';
import _ from 'lodash';

export type FetchEventCompetitionReport = {
  data: Data;
  mergeCellsPosition?: Array<[string, string]>;
  fileName: string;
  mergeStart?: number;
  teams?: any;
  isUniverciad?: boolean;
  event?: CompetitionsResultsFragmentFragment;
  programType?: any;
};

export type Data = Array<AthleteCompetitionResultFragment>;

export const fetchEventCompetitionReport: (props: FetchEventCompetitionReport) => void = async (props) => {
  try {
    const { data = [], fileName, teams = [], event, isUniverciad, programType } = props;

    const workbook = new exceljs.Workbook();
    const blancUrl = isUniverciad
      ? '/templates/blancCompetWithUniverciadReport.xlsx'
      : '/templates/blancCompetReport.xlsx';

    const saveFile = async () => {
      const buffer = await workbook.xlsx.writeBuffer({
        useStyles: true,
      });
      const blob = new Blob([buffer], { type: 'applicationi/xlsx' });
      saveAs(blob, fileName);
    };
    const setCellText = (position: string, value: string) => {
      sheet.getCell(position).value = value;
    };
    const blanc = await (await fetch(blancUrl)).arrayBuffer();
    await workbook.xlsx.load(blanc);
    const sheet = workbook.worksheets[0];

    if (isUniverciad) {
      sheet.getColumn('D').eachCell((cell, index) => {
        if (index > 5) {
          cell.style.alignment = {
            ...cell.style.alignment,
            wrapText: true,
          };
        }
      });
    }

    const [first, ...others] = data;
    const firstCellData = [
      `${first.point}${first.pointTo ? ` - ${first.pointTo}` : ''}`,
      `${first?.dirAthlete?.dirPerson?.lastname} ${first?.dirAthlete?.dirPerson?.firstname} ${
        first?.dirAthlete?.dirPerson?.patronymic || ''
      }`,
      ...(isUniverciad
        ? [
            teams?.find((t: any) => t?.athletesArray?.find((a: DirAthlete) => a?.id === first?.dirAthlete?.id))
              ?.university?.name || '',
          ]
        : []),
      moment(first?.dirAthlete?.dirPerson?.birthday).format('DD.MM.YY'),
      teams?.find((t: any) => t?.athletesArray?.find((a: DirAthlete) => a?.id === first?.dirAthlete?.id))?.region
        ?.label || '',
      first?.result,
      first?.score || '',
    ];
    let i = 0;
    sheet.getRow(6).eachCell((cell) => {
      cell.value = firstCellData[i];
      i += 1;
    });

    for (const result of others) {
      const row = [
        '',
        `${result.point}${result.pointTo ? ` - ${result.pointTo}` : ''}`,
        `${result?.dirAthlete?.dirPerson?.lastname} ${result?.dirAthlete?.dirPerson?.firstname} ${
          result?.dirAthlete?.dirPerson?.patronymic || ''
        }`,
        ...(isUniverciad
          ? [
              teams?.find((t: any) => t?.athletesArray?.find((a: DirAthlete) => a?.id === result?.dirAthlete?.id))
                ?.university?.name || '',
            ]
          : []),
        moment(result?.dirAthlete?.dirPerson?.birthday).format('DD.MM.YY'),
        teams?.find((t: any) => t?.athletesArray?.find((a: DirAthlete) => a?.id === result?.dirAthlete?.id))?.region
          ?.label || '',
        result?.result || '',
        result?.score || '',
      ];
      sheet.addRow(row, 'i');
    }

    const sportType = _.get(event, 'sports[0].dirSport.fullName', '');
    const discipline = _.get(programType, 'discipline.label');
    const calendarName = _.get(event, 'dirCalendar.fullName', '');
    const stageName = _.get(event, 'clsEventStages[0].fullName', '');
    const minAge = _.get(programType, 'minAge', '') ? `${_.get(programType, 'minAge', '')}-` : 'по ';
    const sportCategory =
      _.get(programType, 'clsSportCategory.fullName', '') || _.get(programType, 'clsAgeGroups[0].label', '');
    const eventName = `  ${calendarName} - ${stageName} - ${sportType}`;

    setCellText(isUniverciad ? 'B1:H1' : 'B1:G1', `  ${calendarName} - ${stageName} - ${sportType}`);
    if (eventName.length > 90) {
      sheet.getRow(1).height = 50;
    }
    setCellText(isUniverciad ? 'B2:F2' : 'B2:E2', `  ${event?.venue || ''},${event?.object || ''}`);
    setCellText(isUniverciad ? 'G2:H2' : 'F2:G2', `  ${programType?.start || ''}`);
    setCellText(
      isUniverciad ? 'E3:F3' : 'D3:E3',
      `  ${discipline}, ${sportCategory}, ${minAge}${_.get(programType, 'maxAge', '')}`,
    );

    await saveFile();
  } catch (error) {}
};
