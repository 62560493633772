import {
  UploadFileMutationHookResult,
  useUploadFileMutation,
  useRefereeBankInfoQuery,
  useUpdateRefereeBankInfoMutation,
  UpdateRefereeBankInfoMutationHookResult,
  useDeleteRefereeBankInfoMutation,
  DeleteRefereeBankInfoMutationHookResult,
} from '../../../api';
import React, { FC, useState, useMemo } from 'react';
import { Button, Grid, Link, Tooltip, IconButton, LinearProgress, Typography } from '@material-ui/core';
import { ContentWrapperWidget } from '../../layouts';
import { useSnackbar } from 'notistack';
import getMessage from '../../../messages';
import { makeStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import _ from 'lodash';
import ConfirmDialog from '../../ConfirmDialog';

const useStyles = makeStyles({
  title: {
    fontWeight: 500,
    width: 270,
    display: 'inline-block',
  },
  bTitle: {
    margin: 0,
    fontSize: '16px',
    minWidth: 200,
  },
  subInfo: {
    color: 'lightgrey',
    fontStyle: 'italic',
  },
  link: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'flex-end',
  },
  verticalSpace: {
    paddingBottom: 15,
  },
  verticalSpaceMarge: {
    marginBottom: 15,
  },
  divider: {
    height: 1,
    width: '100%',
    marginBottom: '1rem',
  },
  btn: {
    padding: '5px 5px',
    minWidth: '15px',
  },
  tinyIcon: {
    fontSize: 18,
  },
  icon: {
    fontSize: 20,
  },
  input: {
    display: 'none',
  },
});

const RefereBankInfo: FC<{ readonly?: boolean; id?: string }> = ({ readonly, id }) => {
  const size = 2 * 1048576;

  const DOWNLOAD_LINK = process.env.REACT_APP_BACKEND_URI?.replace('/graphql', '');
  const classes = useStyles();
  const { loading, data, refetch } = useRefereeBankInfoQuery({ variables: { id }, fetchPolicy: 'no-cache' });
  const { enqueueSnackbar } = useSnackbar();

  const bankInfo = useMemo(() => data?.dirReferee?.dirPerson?.bankRequisites || null, [data]);

  const handleSnackBar = (variant: 'default' | 'error' | 'success' | 'warning' | 'info' | undefined, message: string) =>
    enqueueSnackbar(message, { variant });

  const [deleteItem, setDeleteItem] = useState<any>(null);
  const [uploadDocumentFile]: UploadFileMutationHookResult = useUploadFileMutation();
  const [update]: UpdateRefereeBankInfoMutationHookResult = useUpdateRefereeBankInfoMutation();
  const [remove]: DeleteRefereeBankInfoMutationHookResult = useDeleteRefereeBankInfoMutation();

  const handleAddBFile = async (evt: any) => {
    const file = _.get(evt, 'target.files[0]', null);
    if (file.size > size) {
      alert('Размер загружаемого файла превышает допустимые 2Мб!');
      return;
    }
    try {
      if (id !== 'new' && file) {
        uploadDocumentFile({
          variables: {
            file,
          },
        }).then(async (resp: any) => {
          const {
            uploadFile: { id: fileId },
          } = resp.data;
          await update({
            variables: {
              id,
              fileId,
            },
          });
          handleSnackBar('success', `Документ "${file?.name}" успешно добавлен`);
          refetch?.();
        });
      }
    } catch (e) {
      enqueueSnackbar(getMessage(e.message), { variant: 'error' });
    }
  };

  const handleDeleteBFile = async (item: any) => {
    try {
      if (item) {
        await remove({
          variables: {
            id,
          },
        });
        setDeleteItem(null);
        handleSnackBar('success', `Документ "${item?.name}" успешно удален`);
        refetch();
      }
    } catch (e) {
      enqueueSnackbar(getMessage(e.message), { variant: 'error' });
    }
  };

  const DocRow = ({ title, item }: { title: string; item: any }) => (
    <Grid item container alignItems="center" className={classes.verticalSpaceMarge} spacing={2}>
      {item?.path ? (
        <>
          <Grid item>
            <h4 className={classes.bTitle}>{title}:</h4>
          </Grid>
          <Grid item>
            <Link
              // className={`${classes.link}`}
              target="_blank"
              href={`${DOWNLOAD_LINK}${item?.path}`}
            >
              <Button variant="contained" component="span" size="small" color="primary">
                скачать
              </Button>
            </Link>
          </Grid>
          <Grid item>
            <Tooltip title="удалить" placement="top-start">
              <IconButton color="secondary" onClick={() => setDeleteItem({ ...item, name: title })}>
                <DeleteIcon className={classes.icon} />
              </IconButton>
            </Tooltip>
          </Grid>
        </>
      ) : (
        <>
          <Grid item>
            <h4 className={classes.bTitle}>{title}:</h4>
          </Grid>
          <Grid item>
            <label htmlFor="p-file">
              <input
                accept="image/*, .pdf, .jpeg, .png, .jpg"
                className={classes.input}
                id="p-file"
                type="file"
                onChange={(evt: any) => handleAddBFile(evt)}
              />
              <Button variant="contained" component="span" size="small" color="primary">
                добавить
              </Button>
            </label>
          </Grid>
          {!bankInfo && (
            <Grid item container>
              <Typography className={classes.subInfo} variant="subtitle1">
                файл до 2 Mb формата: pdf, jpeg, png
              </Typography>
            </Grid>
          )}
        </>
      )}
    </Grid>
  );

  if (loading) return <LinearProgress />;

  return (
    <ContentWrapperWidget>
      <Grid container>
        <Grid item xs={12}>
          <h4 style={{ marginTop: 0 }}>
            <strong>PDF/Фото</strong> (фотографии должны быть качественные, необрезанные):
          </h4>
        </Grid>
        <DocRow item={bankInfo} title="Банковские реквизиты" />
      </Grid>
      {deleteItem && (
        <ConfirmDialog
          title={`Удаление документа`}
          text={`Вы уверены что хотите удалить документ : ${deleteItem?.name}`}
          btnVariant="outlined"
          open={true}
          saveBtnText="удалить"
          cancelBtnText="отмена"
          saveBtnColor="secondary"
          cancelBtnColor="primary"
          onCancel={() => setDeleteItem(null)}
          onSave={() => handleDeleteBFile(deleteItem)}
        />
      )}
    </ContentWrapperWidget>
  );
};

export default RefereBankInfo;
