import React, { FC } from 'react';
import { useHistory } from 'react-router';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { AppBar, Toolbar, Button, Container } from '@material-ui/core';
import logoOne from '../../static/img/Logo/fcpsr.jpg';
import logoTwo from '../../static/img/Logo/minsport.png';
import { MenuGrid } from '../layouts/MainTemplate/Menu';
import { Helmet } from 'react-helmet';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: '100vh',
      display: 'flex',
      flexDirection: 'column',
    },
    container: {
      marginTop: 64,
      justifyContent: 'center',
      alignItems: 'center',
    },
    menuButton: {
      paddingLeft: 50,
    },
    logo: {
      '& img': {
        height: 61,
      },
    },
    title: {
      display: 'flex',
      flexGrow: 1,
      fontSize: 14,
      justifyContent: 'space-between',
    },
    appBar: {
      backgroundColor: 'transparent',
      boxShadow: 'none',
    },
    btnExit: {
      width: 206,
      height: 70,
      backgroundColor: '#E22B36;',
      boxShadow: '7px 4px 8px rgba(0, 0, 0, 0.25);',
      transition: 'all .3s ease-in',
      '& > span': {
        width: 86,
        height: 29,
        fontWeight: 700,
        fontSize: 24,
        lineHeight: 1.5,
        display: 'flex',
        alignItems: 'center',
        color: '#FFFFFF',
        textTransform: 'none',
      },
      '&:hover': {
        backgroundColor: '#123150;',
      },
    },
  }),
);

const HomePage: FC<{ pageName: string; url: string }> = ({ pageName, url, children }) => {
  const { push } = useHistory();
  const onExit = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('userRole');
    push('/login');
  };
  const classes = useStyles();
  if (!localStorage.getItem('token')) {
    push('/login');
    return null;
  }
  return (
    <div className={classes.root}>
      <Helmet>
        <title>АИС ФЦПСР</title>
      </Helmet>
      <AppBar position="static" className={classes.appBar}>
        <Container maxWidth="lg">
          <Toolbar className={classes.title}>
            <div className={classes.menuButton} color="secondary" aria-label="menu">
              <span className={classes.logo}>
                <img src={logoTwo} />
              </span>
              <span className={classes.logo}>
                <img src={logoOne} />
              </span>
            </div>
            <Button variant="contained" color="secondary" onClick={onExit} className={classes.btnExit}>
              Выйти
            </Button>
          </Toolbar>
        </Container>
      </AppBar>
      <div className={classes.container}>
        <MenuGrid url={url} />
      </div>
    </div>
  );
};

export default HomePage;
